// @ts-nocheck
import React, { useEffect } from "react";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import {  API_KEY, CLIENT_ID, DISCOVERY_DOC_DATA, SCOPES_DATA } from "./asset";
import { BookingDates } from "./TypeInterfaces.web"

let tokenClient;

interface Props {
    syncToCalendar : boolean
    bookingDates : BookingDates
    navToHome : () => void
}

const GoogleCalender = (props : Props) => {
    const clientId = CLIENT_ID;
    const apiKey = API_KEY;
    const DISCOVERY_DOC = DISCOVERY_DOC_DATA
    const SCOPES = SCOPES_DATA;

    const bookingDetails = {
        summary: "Upcoming Booking Info",
        location: "",
        description: "Your upcoming booking is confirmed! Please check your reservation details, set reminders, and make any necessary arrangements to ensure a smooth, enjoyable experience in the days ahead.",
        start: {
          dateTime: props.bookingDates.startDate,
          timeZone: "UTC",
        },
        end: {
          dateTime: props.bookingDates.endDate,
          timeZone: "UTC",
        },
        recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
        reminders: {
          useDefault: false,
          overrides: [
            { method: "email", minutes: 24 * 60 },
            { method: "popup", minutes: 10 },
          ],
        },
      };

    useEffect(() => {
        loadApi();
        loadGis();
    }, []);

    const loadApi = () => {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            gapi.load("client", initializeGapiClient);
        };
    };

    const loadGis = () => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.defer = true;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            tokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: clientId,
                scope: SCOPES,
                callback: "",
            });
        };
    };

    const initializeGapiClient = async () => {
        await gapi.client.init({
            apiKey: apiKey,
            discoveryDocs: [DISCOVERY_DOC],
        });
    };

    const handleAuthClick = async () => {
        tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) {
                throw resp;
            }
            addEvent();
        };
        if (gapi.client.getToken() === null) {
            tokenClient.requestAccessToken({ prompt: "consent" });
        } else {
            tokenClient.requestAccessToken({ prompt: "" });
        }
    };

    const handleSignOutClick = () => {
        const token = gapi.client.getToken();
        if (token !== null) {
            window.google.accounts.oauth2.revoke(token.access_token);
            gapi.client.setToken("");
        }
    };

    const addEvent = async () => {
        try {
            const request = gapi.client.calendar.events.insert({
                calendarId: "primary",
                resource: bookingDetails,
            });

            request.execute((event) => {
                toast.success("Event added in your calender!");
                handleSignOutClick();
                props.navToHome()
            });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        props.syncToCalendar && handleAuthClick()
    }, [props])

    return (
        <></>
    );
}

export default GoogleCalender;