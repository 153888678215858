// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import {toast} from "react-toastify"
import { CardDetails } from "../../../components/src/TypeInterfaces.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface LogInFields {
    email: string,
    password: string
}

interface SignUpFields {
    first_name: string ,
    last_name: string,
    phone_number: string
    email: string,
    password: string
}

interface CardInfo {
  "card_number": string,
  "card_holder_name": string,
  "expiry_date": string,
  "security_code": string,
  "street": string,
  "city": string,
  "state":string,
  "zip_code": string,
  "country": string
}

interface CardInfoErrors {
  "card_number": boolean,
  "card_holder_name": boolean,
  "expiry_date": boolean,
  "security_code": boolean,
  "street": boolean,
  "city": boolean,
  "state":boolean,
  "zip_code": boolean,
  "country": boolean
}

interface SignUpErrorFields {
    first_name: boolean ,
    last_name: boolean,
    phone_number: boolean
    email: boolean,
    password: boolean
}

interface LogInErrorFields {
    email: boolean,
    password: boolean
}

interface S {
  // Customizable Area Start  
  loginFormData : LogInFields,
  loginErrorFields : LogInErrorFields
  signupFormData: SignUpFields,
  signupErroFields: SignUpErrorFields,
  isPasswordVisible : boolean,
  termsCheck: boolean,
  activeStepperStep : number,
  verifyOtp:string,
  cardInformation : CardInfo,
  cardInformationError: CardInfoErrors,
  displayOTP: string,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LogInController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  logInApiCallId: string = "";
  signUpApiCallId: string = "";
  verifyOTPApiCallId: string = "";
  addPaymentMethodApiCallId: string = "";
  getUserProfileApiCalId: string ="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loginFormData: {
            email: "",
            password: ""
        },
        loginErrorFields: {
            email : false,
            password: false
        },
        signupFormData: {
            first_name: "",
            last_name: "",
            phone_number: "",
            email: "",
            password: ""
        },
        signupErroFields: {
            first_name: false,
            last_name: false,
            phone_number: false,
            email: false,
            password: false
        },
        isPasswordVisible: false,
        termsCheck: false,
        activeStepperStep: 1,
        verifyOtp: "",
        cardInformation: {
          "card_number": "",
          "card_holder_name": "",
          "expiry_date": "",
          "security_code": "",
          "street": "",
          "city": "",
          "state": "",
          "zip_code": "",
          "country": ""
        }, 
        cardInformationError : {
          "card_number": false,
          "card_holder_name": false,
          "expiry_date": false,
          "security_code": false,
          "street": false,
          "city": false,
          "state": false,
          "zip_code": false,
          "country": false
        },
        displayOTP: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleAPIsCalls(apiRequestCallId, responseJson)

      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleAPIsCalls = (apiRequestCallId : string,responseJson: any) => {
    switch(apiRequestCallId) {
        case this.logInApiCallId :
            this.logInAPIResponse(responseJson)
            break;
        case this.signUpApiCallId :
             this.SignUpAPIResponse(responseJson)
             break;
        case this.verifyOTPApiCallId : 
             this.verifyOTPAPIResponse(responseJson)
             break;
        case this.addPaymentMethodApiCallId : 
             this.addPaymentMethodAPIResponse(responseJson)
             break;
        case this.getUserProfileApiCalId : 
             return this.getUserProfileApiResponse(responseJson)

    }
  }

  getUserProfileApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.user && responseJson.user.data) {
       const userImg = truthyValue(responseJson.user.data.attributes.profile_image_url.url)
       localStorage.setItem("userImg", userImg)
       this.props.navigation.navigate("Home")
    }
  }

  logInAPIResponse = (responseJson: any) => {
    if(responseJson && responseJson.meta) {
        toast.success("Succefully Logged In")
        localStorage.setItem("userRole", responseJson.meta.role)
        localStorage.setItem("userToken", responseJson.meta.token)
        localStorage.setItem("accountId", responseJson.meta.id)
        this.getUserProfile()
    } else if(responseJson && responseJson.errors ) {
        const errors = responseJson.errors[0].failed_login 
        toast.error(errors)
    }
  }

  SignUpAPIResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
      const role = responseJson.data.attributes.role
      const token = responseJson.meta.token
      const otpResponse = responseJson.meta.pin
      const id = responseJson.meta.id
        localStorage.setItem("userRole", role)
        localStorage.setItem("userToken", token)
        localStorage.setItem("accountId", id)
        this.setState({ activeStepperStep : this.state.activeStepperStep + 1, displayOTP: otpResponse})
    } else if(responseJson && responseJson.errors ) {
        const errors = responseJson.errors[0]
        toast.error(errors)
    }
  }

  verifyOTPAPIResponse = (responseJson: any) => {
    if(responseJson && responseJson.messages) {
      toast.success(responseJson.messages)
      this.setState({ activeStepperStep : this.state.activeStepperStep + 1})  
    } else if(responseJson && responseJson.errors ) {
        const errors = responseJson.errors
        toast.error(errors)
    }
  }

  addPaymentMethodAPIResponse = (responseJson: any) => {
    if(responseJson && responseJson.data) {
      toast.success("Successfully SignUp")
      this.navigateToHomePage()
    } else if(responseJson && responseJson.error ) {
        let errMsg = ""
        if(responseJson.error && responseJson.error.card_number) {
           errMsg = responseJson.error.card_number[0]
           toast.error(errMsg)
        } 
        errMsg = responseJson.error.message
        toast.error(errMsg)
    }
  }

  navigateToLoginPage = () => {
    this.props.navigation.navigate("LogIn")
  }

  navigateToHomePage = () => {
    this.props.navigation.navigate("Home")
  }

  navigateToSignUpPage = () => {
    this.props.navigation.navigate("SignUp")
  }

  handleChangeForLogin = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formName = event.target.name
    const formValue = event.target.value

    if(formValue.trim() === "") {
        this.setState({ loginErrorFields: { ...this.state.loginErrorFields, [formName] : true }})
    } else {
        this.setState({ loginErrorFields: { ...this.state.loginErrorFields, [formName] : false }})
    }

    this.setState({ loginFormData: { ...this.state.loginFormData, [formName] : formValue }})
    
  }

  handleChangeForSignup = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formName = event.target.name
    const formValue = event.target.value

    if(formValue.trim() === "") {
        this.setState({ signupErroFields: { ...this.state.signupErroFields, [formName] : true }})
    } else {
        this.setState({ signupErroFields: { ...this.state.signupErroFields, [formName] : false }})
    }

    this.setState({ signupFormData: { ...this.state.signupFormData, [formName] : formValue }})
    
  }

  handleAllValidationOnSignup = () => {
    let checkValidation = {
        first_name: false,
        last_name: false,
        phone_number: false,
        email: false,
        password: false
      };
  
      if (this.state.signupFormData.first_name === "") {
        checkValidation.first_name = true;
      } if (this.state.signupFormData.last_name === "") {
        checkValidation.last_name = true;
      } if (this.state.signupFormData.email === "") {
        checkValidation.email = true;
      } if (this.state.signupFormData.phone_number === "") {
        checkValidation.phone_number = true;
      } if (this.state.signupFormData.password === "") {
        checkValidation.password = true;
      }
  
      this.setState({ signupErroFields : checkValidation})
  
      return Object.values(checkValidation).every((value) => value === false);
  }

  handleAllValidationOnLogin = () => {
    let checkValidatin = {
        email: false,
        password: false,
      };
  
      if (this.state.loginFormData.email === "") {
        checkValidatin.email = true;
      } if (this.state.loginFormData.password === "") {
        checkValidatin.password = true;
      }
  
      this.setState({ loginErrorFields : checkValidatin})
  
      return Object.values(checkValidatin).every((value) => value === false);
  }

  handleSignUp = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    
    if(!this.handleAllValidationOnSignup()) {
       return false;
    }

    const httpBody = {
        data : {
            attributes : {
                "first_name": this.state.signupFormData.first_name,
                "last_name": this.state.signupFormData.last_name,
                "full_phone_number": this.state.signupFormData.phone_number,
                "email": this.state.signupFormData.email,
                "password": this.state.signupFormData.password,
                "activated": "false"
            }
        }
    }

    const header = {
        "Content-Type": configJSON.productApiContentType,
      };

      this.signUpApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.signUpApiEndPoints,
        method: configJSON.apiMethodTypePost,
        body: JSON.stringify(httpBody)
    })
    
  }

  handleLogIn = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    
    if(!this.handleAllValidationOnLogin()) {
       return false;
    }
        

     const header = {
        "Content-Type": configJSON.productApiContentType,
      };

    const httpBody = {
        data : {
            attributes : {
                email: this.state.loginFormData.email,
                password: this.state.loginFormData.password
            }
        }
    }

    this.logInApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.loginApiEndPoints,
        method: configJSON.apiMethodTypePost,
        body: JSON.stringify(httpBody)
    })

  }

  handleClickShowPassword = () => {
    this.setState({ isPasswordVisible : !this.state.isPasswordVisible})
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  signUpStepperSteps = [
    { id : 1, label: "Sign up", activeKey : 1},
    { id : 2, label: "Verify Number", activeKey : 2},
    { id : 3, label: "Scan Driver Licence", activeKey : 3},
    { id : 4, label: "Scan Face", activeKey : 4},
    { id : 5, label: "Set up accounts", activeKey : 5},
  ]

  handleVerifyOTP = (otpValue : string) => {
     this.setState({ verifyOtp: otpValue})
  } 

  verifyOTP = () => {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("userToken")
    };

    const httpBody = {
      "data": {
        "attributes": {
          "pin": this.state.verifyOtp
        }
      }
    }

    this.verifyOTPApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.verifyOTPApiEndpoint,
      body: JSON.stringify(httpBody)
    })
  }

  addPaymentMethod = (payload : CardDetails) => {

    const header = {
      "token": localStorage.getItem("userToken")
    };

    const formData = new FormData()

    formData.append("card[card_token]", payload.card_token)
    formData.append("card[address_line1]", payload.street)
    formData.append("card[city]", payload.city)
    formData.append("card[state]", payload.state)
    formData.append("card[postal_code]", payload.zip_code)
    formData.append("card[country]", payload.country)

    this.addPaymentMethodApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.addPaymentMethodApiEndPoint,
      method: configJSON.apiMethodTypePost,
      body: formData
    })

    
  }

  handleNextStep = () => {
    this.setState({ activeStepperStep : this.state.activeStepperStep + 1})
  }

  getUserProfile = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getUserProfileApiCalId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getUserProfileApiEndPoint,
    })
  }


  // Customizable Area End
}

// Customizable Area End