// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { EmojiClickData } from "emoji-picker-react";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling, mockTransactionData, redirectToURL, truthyCardTitleValue, truthyNumberValue, truthyValue } from "../../../components/src/asset";
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import { ApexOptions } from "apexcharts"
import { HostEarningPayout, HostTransaction, HostTransactionBookedSlot, MonthData, PriceBreakDown, SnapShotGraphData } from "../../../components/src/TypeInterfaces.web";
import moment from "moment"

export const configJSON = require("./config");

export interface DataItem {
  id: string;
  estimate_time: string;
  received: boolean;
  total_revenue: string;
}
export interface ReviewData {
  id: string;
  type: string;
  attributes: {
      id: string;
      catalogue_id: string;
      rating: number;
      comment: string;
      anonymity: boolean;
      reviewer_name: string;
      catalogue_name: string;
      catalogue_type: string;
      profile_img: {
          url: string;
      };
      created_at: string;
      time_ago: string;
      web_time_ago: string;
  }
}

interface Reviews {
  error: string;
  reviews: {
    data: ReviewData[]
  },
  average_rating: string;
  host_created: string;
  total_pages: number;
  current_page: number;
  rating_1: number;
  rating_2: number;
  rating_3: number;
  rating_4: number;
  rating_5: number
}

interface ScheduleData {
  day: string;
  date: number;
  value: number[] | null;
  catalogueType : string[]
}

interface CustomDataValue {
  [key: string]: number;
}

interface CustomData {
  data: {
    [range: string]: CustomDataValue;
  }
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  tabValue: number,
  noOfHome: number,
  noOfCars: number,
  listData: any[],
  slider: boolean,
  addListModal: boolean,
  value:number,
  payoutData: DataItem[],
  openPayoutModal: boolean,
  openTransactionModal: boolean,
  reviewData: Reviews,
  replySection:boolean[];
  showMore:boolean[];
  replySectionId:number;
  showEmojiPicker: boolean;
  replyValues: { [key: number]: string };
  selectedReplyIndex:number;
  seeAllBtn:boolean
  loader : boolean;
  options: ApexOptions;
  series: ApexAxisChartSeries,
  hostScheduleData : ScheduleData[],
  loaderOverView : boolean,
  hostPayoutData : HostEarningPayout[]
  selectedPayOutData : HostEarningPayout
  hostTransactionData : HostTransaction[]
  selectedTransactionData : HostTransaction,
  transactionPriceSteeper : PriceBreakDown[],
  selectedMonthFilter : number,
  earningGraphData : number[],
  earningCatogoriesGraphData : string[]
  totalEarning : number
  snapShotGraphData : SnapShotGraphData[]
  snapShotSelectedFilter : number
  saveAllStaticsData : any[],
  stripeAcountId : string
  stripeAccLink : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  becomeHostAPICallId: string = "";
  listCatalougeApiCallId: string = "";
  earningTabDataAPICallId:string="";
  reviewDataApiCallID: string ="";
  sendReplyApiCallID:string="";
  getHostScheduleApiCallId : string = ""
  getTransactionBreakdownApiCallId : string = ""
  getHostAllStaticsApiCallId : string = ""
  getUserInfoApiCallId : string = ""
  createHostAccountApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        tabValue : 0,
        noOfHome: 0,
        noOfCars: 0,
        listData: [],
        slider: false,
        addListModal: false,
        value:0,
        payoutData:[],
        openPayoutModal:false,
        openTransactionModal: false,
        reviewData:{
          reviews: {
            data: []
          },
          average_rating: "",
          host_created: "",
          total_pages: 0,
          current_page: 0,
          rating_1: 0,
          rating_2: 0,
          rating_3: 0,
          rating_4: 0,
          rating_5: 0,
          error: ""
        },
        replySection:[],
        showMore:[],
        replyValues:{},
        replySectionId:0,
        showEmojiPicker: false,
        selectedReplyIndex:0,
        seeAllBtn:false,
        loader : true,
        series: [
          {
            name: "Series 1",
            data: [20, 30, 32, 25, 38, 50, 45, 25, 23, 35, 40, 45],
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
          },
        },
        hostScheduleData : this.generateDynamicSchedule(),
        loaderOverView: true,
        hostPayoutData : [],
        selectedPayOutData : this.blankHostEarningPayout,
        hostTransactionData : [],
        selectedTransactionData : this.blankHostTransactionBreakdown,
        transactionPriceSteeper : this.initialTripCostBreakdown,
        selectedMonthFilter :12,
        earningGraphData : [],
        earningCatogoriesGraphData : [],
        totalEarning : 0,
        snapShotGraphData : this.initialSnapShotGraphData,
        snapShotSelectedFilter : 12,
        saveAllStaticsData : [],
        stripeAcountId : "",
        stripeAccLink : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.becomeHostAPICallId:
          return this.responseBecomeHostAPI(responseJson)
        case this.listCatalougeApiCallId:
          return this.responseListCatalougeDataAPI(responseJson)
        case this.getHostScheduleApiCallId:
          return this.responseForHostScheduleApi(responseJson)
        case this.earningTabDataAPICallId:
          return this.setPayoutDataCall(responseJson)
        case this.reviewDataApiCallID:
          return this.setReviewDataCall(responseJson)
        case this.sendReplyApiCallID:
          return this.setState({ showEmojiPicker: false })
        case this.getTransactionBreakdownApiCallId:
          return this.handleTransactionBreakdownResponse(mockTransactionData)
        case this.getHostAllStaticsApiCallId:
          return this.handleTotalEarningsGraphResponse(responseJson)
        case this.getUserInfoApiCallId : 
          return this.handleUserProfileApiResponse(responseJson)
        case this.createHostAccountApiCallId :
          return this.handleCreateAccountApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setReplySectionFalse = (index:number) => {
    this.setState(prevState => {
      const newReplySection = [...prevState.replySection];
      newReplySection[index] = false;
      return { replySection: newReplySection };
    });
  };
  setReviewDataCall = (response: Reviews) => {
    if(!response.error){
      this.setState({reviewData:response})

    }
  }
  setPayoutDataCall = (responseJson: any) => {
    if (responseJson && responseJson.length > 0) {
      const flatArr = responseJson.flatMap((item: any) => item.payouts);
      const payOutArr = flatArr.map((itemValue : any) => {
        return {
          title: truthyValue(itemValue.title),
          created_at: moment(itemValue.created_at).format('MMM DD, YYYY'),
          amount: truthyValue(itemValue.amount),
          booked_slots: itemValue.booked_slots.map((bookedItem : any) => {
            return {
              catalogueName: truthyValue(bookedItem.catalogue_name),
              catalogueDuration: `${truthyValue(bookedItem.total_days)} Days`,
              startDate: moment(truthyValue(bookedItem.start_time)).format('MMM Do') ,
              endDate: moment(truthyValue(bookedItem.end_time)).format('MMM Do'),
              cataloguePrice: truthyValue(bookedItem.amount),
              catalogueImageUrl: truthyValue(bookedItem.image),
            }
          })
        }
      })

      this.setState({ hostPayoutData : payOutArr})
    }
  }

  handleTransactionBreakdownResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const flatArr = responseJson.data.flatMap((item: any) => item.transactions);
      const transactionArr = flatArr.map((arrayItem : any) => {
        const bookSlotType = arrayItem.booked_slots.length > 0
          ? truthyValue(arrayItem.booked_slots[0].catalogue_type)
          : "";
        return {
          title: truthyValue(arrayItem.title),
          created_at: moment(arrayItem.created_at).format('MMM DD, YYYY'),
          amount: truthyValue(arrayItem.amount),
          transactionStatus : truthyValue(arrayItem.status),
          bookSlotType : this.handleTransactionTypCondition(bookSlotType),
          booked_slots: arrayItem.booked_slots.map((catalougeItem : any) => {
            return {
              catalogueName: truthyValue(catalougeItem.catalogue_name),
              catalogueDuration: `${truthyValue(catalougeItem.total_days)} Days`,
              startDate: moment(truthyValue(catalougeItem.start_time)).format('MMM D') ,
              endDate: moment(truthyValue(catalougeItem.end_time)).format('MMM D'),
              cataloguePrice: truthyValue(catalougeItem.amount),
              catalogueImageUrl: truthyValue(catalougeItem.image),
              catalogueType : truthyValue(catalougeItem.catalogue_type)
            }
          })
        }
      })

      this.setState({ hostTransactionData : transactionArr})
    }
  }

  handleTotalEarningsGraphResponse = (responseJson: any) => {
    if (responseJson && responseJson.statistics &&  responseJson.statistics.data && responseJson.statistics.data.length > 0) {
       const receivedArray = responseJson.statistics.data
       const totalEarnings = truthyValue(responseJson.total_earnings)

       const modifiedArray = this.initialMonthArray.map(month => {
        const statistic = receivedArray.find(
          (stat: any) => stat.attributes.month === month.value
        );
        
        if (statistic) {
          return { ...month, earningValue: statistic.attributes.earnings };
        }
      
        return month;
      });

      const lastNMonthsData = this.getLastNMonthsData(modifiedArray,this.state.selectedMonthFilter)
      
      const earningData = lastNMonthsData.map(item => item.earningValue)
      const earningCatogoriesGraphData = lastNMonthsData.map(item => item.monthName)

      const rentalCount = receivedArray.reduce((sum: number, item: any) => {
        return sum + item.attributes.rentals_count;
      }, 0);

      const revenueCount = receivedArray.reduce((sum: number, item: any) => {
        return sum + item.attributes.earnings;
      }, 0)/ receivedArray.length;

      const reviewCount = receivedArray.reduce((sum: number, item: any) => {
        return sum + item.attributes.average_rating;
      }, 0)/ receivedArray.length;

      const modifiedSnapShotGraphData : SnapShotGraphData[] = [
        {
          id : 1,
          label: "Monthly Rentals",
          value: 0,
          progressTitle: rentalCount,
          progressValue: 0,
          progressUpgrade: false,
          progressUpgradeCount: "+0",
        },
        {
          id : 2,
          label: "Avg. Monthly Revenue",
          value: 0,
          progressTitle: `$${truthyNumberValue(revenueCount)}`,
          progressValue: 0,
          progressUpgrade: false,
          progressUpgradeCount: "+0",
        },
        {
          id : 3,
          label: "Avg. Rating",
          value:Number(reviewCount)/5*100,
          progressTitle: `${truthyNumberValue(reviewCount)}`,
          progressValue: 0,
          progressUpgrade: false,
          progressUpgradeCount: "+0",
        }
      ]

      

       this.setState({ 
         totalEarning : totalEarnings, 
         earningCatogoriesGraphData, 
         earningGraphData : earningData,
         snapShotGraphData : modifiedSnapShotGraphData,
         saveAllStaticsData : receivedArray
      })
    }
  }

  handleUserProfileApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.user && responseJson.user.data) {
       const stripeAccountId = responseJson.user.data.attributes.stripe_account_id !== null  ? truthyValue(responseJson.user.data.attributes.stripe_account_id) : ""
       this.setState({ stripeAcountId : stripeAccountId})
    }
  }

  handleCreateAccountApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.account_link) {
      toast.success("Please fill in the required details on the next page.")
       const accLink = truthyValue(responseJson.account_link)
       this.setState({ stripeAccLink : accLink, addListModal : false, loader : false})
       redirectToURL(accLink)
    } else {
       toast.error("Something went wrong. Please try agailn later")
       this.setState({ addListModal : false, loader : false})
    }
  }

  async componentDidMount(): Promise<void> {
     this.getHostScheduleData()
     this.getUserDetails()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.tabValue === 1 && this.state.tabValue !== prevState.tabValue) {
      this.getUserCatalougeData()
    }
    if (this.state.tabValue === 2 && this.state.tabValue !== prevState.tabValue) {
      this.EarningPayoutData()
      this.getTransactionBreakdownData()
      this.getAllHostEarningsData()
    }
    if (this.state.tabValue === 3 && this.state.tabValue !== prevState.tabValue) {
      this.getReviewData()
    }
    if (this.state.seeAllBtn !== prevState.seeAllBtn) {
      this.EarningPayoutData()
    }
    if(this.state.tabValue === 0 && this.state.tabValue !== prevState.tabValue) {
      this.getHostScheduleData()
    }
  }
   handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
     this.setState({ tabValue : newValue})
  };

  responseBecomeHostAPI = (responseJson: any) => {
    if(responseJson && responseJson.message) {
      toast.success("Your role has been succesfully changed to Host. Please login to Continue")
      localStorage.clear()
      this.props.navigation.navigate("LogIn")
    } else {
      toast.error("Something went wrong!")
    }
  }

  sliderOn = () => {
    this.setState({ slider : true})
  }

  sliderOff = () => {
    this.setState({ slider : false})
  }

  handleCloseAddListModal = () => {
    this.setState({ addListModal : false})
  }

  handleOpeneAddListModal = () => {
    this.setState({ addListModal : true})
  }

  responseListCatalougeDataAPI = (responseJson: any) => {
    if(responseJson && responseJson.data) {
      const nmberOfCars = responseJson.meta.vehicles
      const nmberOfHomes = responseJson.meta.homes
      this.setState({ noOfCars : nmberOfCars, noOfHome: nmberOfHomes, listData: responseJson.data, loader: false})
    } else {
      this.setState({ listData: [], loader: false})
    }
  }

  responseForHostScheduleApi = (responseJson: CustomData) => {
    if(responseJson && responseJson.data && Object.keys(responseJson.data).length > 0) {

      const results: { range: string, startDate: number | null, value: number | null, catalogueType: string | null }[] = [];

      for (const [rangeKey, valueData] of Object.entries(responseJson.data)) {
        const { startDate, value, catalogueType } = this.extractStartDateAndDetails(rangeKey, valueData);
        results.push({ range: rangeKey, startDate, value, catalogueType });
      }

      const newArr = this.generateResponse(results)

      this.setState({ hostScheduleData : newArr, loaderOverView: false})
    } else {
      this.setState({ loaderOverView: false})
    }
  }

  generateResponse = (results: any[]): ScheduleData[] => {
    const response: any[] = [];
  
    for (let i = 1; i <= 30; i++) {
      response.push({
        day: this.getDayName(i),
        date: i,
        value: [],
        catalogueType: []
      });
    }

    
    results.forEach(result => {
      const { startDate, value, catalogueType } = result;

     
      if (startDate >= 1 && startDate <= 30) {
        const item = response[startDate - 1];
        if (item) {
          item.value.push(value);
          item.catalogueType.push(catalogueType);
        }
      }
    });

    return response;
  };

  getDayName = (date: number): string => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const d = new Date(2024, 8, date); 
    return days[d.getDay()];
  };

  becomeHost = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("userToken")
    };

    this.becomeHostAPICallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.becomeHostApiEndPoint
    })
  }

  getUserCatalougeData = () => {
    const headerWeb = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("userToken")
    };
    
    this.listCatalougeApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      endPoint: configJSON.getUsersCatalougeApiEndpoint,
      method: configJSON.apiMethodTypeGet
    })
  }

  getHostScheduleData = () => {
    const headerWeb = {
      "token": localStorage.getItem("userToken")
    };

    const startDateTime = this.getMonthStartAndEnd().startDateTime + "T00:00:00"
    const endDateTime = this.getMonthStartAndEnd().endDateTime + "T23:59:59"
    
    this.getHostScheduleApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      endPoint: `${configJSON.getHostScheduleApiEndPoint}?search_start_date=${startDateTime}&search_end_date=${endDateTime}`,
      method: configJSON.apiMethodTypeGet
    })
  }

  getTransactionBreakdownData = () => {
    const headerWeb = {
      "token": localStorage.getItem("userToken")
    };

    this.getTransactionBreakdownApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      endPoint: configJSON.getTransactionBreakdownApiEndPoint,
      method: configJSON.apiMethodTypeGet
    })
  }

  getAllHostEarningsData = () => {
    const headerWeb = {
      "token": localStorage.getItem("userToken")
    };

    this.getHostAllStaticsApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      endPoint: configJSON.getHostAllStaticsApiEndPoint,
      method: configJSON.apiMethodTypeGet
    })
  }

  getUserDetails = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    this.getUserInfoApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.getUserProfileApiEndPoint
    })
  }

  createStripeAccount = () => {
    this.setState({ loader : true})
    
    const header = {
        token : localStorage.getItem("userToken")
    }

    const currentUrl = window.location.href

    const formData = new FormData()

    formData.append("return_url", currentUrl)

    this.createHostAccountApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.createStripeAccountApiEndPoint,
        body : formData
    })
  }

  getMonthStartAndEnd(): { startDateTime: string; endDateTime: string } {
    const now = new Date();

    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const formatDate = (date: Date): string => date.toISOString().slice(0, 10); 

    return {
      startDateTime: formatDate(startDate),
      endDateTime: formatDate(endDate)
    };
  }

  generateDynamicSchedule(): ScheduleData[] {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate(); 
    const scheduleData: ScheduleData[] = [];
  
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayName = date.toLocaleString('default', { weekday: 'short' });
  
      scheduleData.push({
        day: dayName,
        date: day,
        value: null,
        catalogueType: []
      });
    }
  
    return scheduleData;
  }

  extractStartDateAndDetails = (range: string, data: CustomDataValue): { startDate: number | null, value: number | null, catalogueType: string | null } => {
    const [startStr] = range.split(' to ').map(dateStr => dateStr.trim());
    const date = new Date(`${startStr} 2024`); 
    
    const [catalogueType] = Object.keys(data);
    const value = data[catalogueType] || null;
  
    return {
      startDate: !isNaN(date.getDate()) ? date.getDate() : null,
      value,
      catalogueType: catalogueType || null
    };
  }

  navigateToListInfoPage = (catalogueId:number,catalogueType: string ) => {
    this.props.navigation.navigate("ListingSettings",{catalogueId,catalogueType})
  };

  navigateToAddCarListingPage = () => {
    this.props.navigation.navigate("AddCarListing")
  };

  navigateToAddHomeListingPage = () => {
    this.props.navigation.navigate("AddHomeListing")
  };

  navigateToGivenPage = (navUrl : string) => {
    if(navUrl !== "") {
      this.props.navigation.navigate(navUrl)
      return false
    }
  };

  formatCarNumberPlate = (actualString : string) => {

      const letterMatches = actualString.match(/[a-zA-Z]+/g);
      const digitMatches = actualString.match(/\d+/g);
      
      const letters = letterMatches ? letterMatches.join('') : '';
      const digits = digitMatches ? digitMatches.join('') : '';
      
      const formattedLetters = letters.slice(-2).toUpperCase();
      const formattedDigits = digits.slice(-4);
      
      return `${formattedLetters} ${formattedDigits}`;
  }

  handleCardTitle = (item : any) => {
     return item.attributes.name === "Car" ? `${item.attributes.sub_category} (${item.attributes.car_year})` : `${truthyCardTitleValue(item.attributes.features, "home_title")}`
  }

  handleCardHeader = (item : any) => {
    return item.attributes.name === "Car" ? `Plate : ${item.attributes.car_number_plate && this.formatCarNumberPlate(item.attributes.car_number_plate)}` : `${item.attributes.address?.address} ${item.attributes.address?.country}`
 }

 handleCardTrips = (item : any) => {
  return item.attributes.name === "Car" ? `Trips : ${item.attributes.trips_completed} completed` : `Bookings: ${item.attributes.trips_completed} completed`
}
handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  this.setState({ value: newValue });
};

a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};
EarningPayoutData = () => {
  const header = {
    "token": localStorage.getItem("userToken")
  };

  this.earningTabDataAPICallId = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypeGet,
    endPoint: configJSON.getPayoutScheduleApiEndPoint
  })
}
handlePayoutModal = (bookedData : HostEarningPayout) => {
   this.setState({openPayoutModal: true, selectedPayOutData: bookedData})
}
closePayoutModal = () => {
  this.setState({openPayoutModal: false, selectedPayOutData: this.blankHostEarningPayout})
}

handleTransactionModal = (dataValue : HostTransaction) => {
  this.setState({ openTransactionModal: true, selectedTransactionData : dataValue })
}

closeTransactionModal = () => {
  this.setState({ openTransactionModal: false, selectedTransactionData : this.blankHostTransactionBreakdown })
}
getReviewData = () => {
  const header = {
    "token": localStorage.getItem("userToken")
  };

  this.reviewDataApiCallID = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypeGet,
    endPoint: configJSON.reviewDataEndPoints
  })
}
handleComment = (index: number) => {
  this.setState(prevState => {
    const newReplySection = [...prevState.replySection];
    newReplySection[index] = !newReplySection[index];
    return { replySection: newReplySection };
  });
  this.setState({replySectionId:index})
}
showComment = (comment:string, index:number) => {
if(comment.length>180 && !this.state.showMore[index]){
  return `${comment.slice(0,181)}...`
}
else{
  return comment
}
}
seeMoreBtn = (index: number) => {
  this.setState(prevState => {
    const newShowMore = [...prevState.showMore];
    newShowMore[index] = true;
    return { showMore: newShowMore };
  });
};

seeLessBtn = (index: number) => {
  this.setState(prevState => {
    const newShowMore = [...prevState.showMore];
    newShowMore[index] = false;
    return { showMore: newShowMore };
  });
};

sendReplyApiCall = (replyId: number | string, index: number) => {
  this.setReplySectionFalse(index)
  const header = {
    token: localStorage.getItem("userToken"),
    "Content-Type": "application/json",
  };
  const httpBody = {
    comment: this.state.replyValues[index],
  };
  this.sendReplyApiCallID = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypePost,
    endPoint: configJSON.sendReplyEndPoints(replyId),
    body: JSON.stringify(httpBody),
  });

  this.setState((prevState) => ({
    replyValues: {
      ...prevState.replyValues,
      [index]: "",
    },
  }));
};
setSelectedReplyIndex = (index: number) => {
  this.setState({ selectedReplyIndex: index, showEmojiPicker: true });
};
onEmojiClick = (emojiData: EmojiClickData) => {
  const { selectedReplyIndex } = this.state;
  this.setState((prevState) => ({
    replyValues: {
      ...prevState.replyValues,
      [selectedReplyIndex]: (prevState.replyValues[selectedReplyIndex] || "") + emojiData?.emoji,
    },
  }));
};

handleReply = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  this.setState((prevState) => ({
    replyValues: {
      ...prevState.replyValues,
      [index]: value,
    },
  }));
};

handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  if (event.key === "Enter") {
    this.setState({ showEmojiPicker: false });
  }
};
handleSeeAllBtn = () => {
  this.setState({seeAllBtn: true})
}
handleSeeLessBtn = () => {
  this.setState({seeAllBtn: false})
}

  handlePayouTCondition = (keyValue: string) => {
    const compareString  = "Automatic Payout"
    return keyValue.trim() === compareString.trim() ? true : false
  }

  handleTransactionTypCondition = (keyValue: string) => {
    const compareString  = "Home"
    return keyValue.trim() === compareString.trim() ? true : false
  }

  blankTransactionBookedSlot: HostTransactionBookedSlot[] = [
    {
      catalogueName: "",
      catalogueType: "",
      catalogueDuration: "",
      startDate: "",
      endDate: "",
      cataloguePrice: "",
      catalogueImageUrl: ""
    }
  ]

  blankHostEarningPayout: HostEarningPayout = {
    title: "",
    created_at: "",
    amount: 0,
    booked_slots: []
  };

  blankHostTransactionBreakdown: HostTransaction = {
    title: "",
    created_at: "",
    amount: 0,
    booked_slots: this.blankTransactionBookedSlot,
    transactionStatus: false,
    bookSlotType: ""
  };

  

  initialTripCostBreakdown: PriceBreakDown[] = [
    {
      label: 'Rental payment',
      details: '$0 / Night',
      subDetails: "(x0 Night)",
      amount: '$0',
    },
    {
      label: 'Stay Fees',
      details: 'Extended Trip Discount',
      subDetails: "(5%)",
      amount: '$0',
    },
    {
      label: 'House Taxes',
      details: 'Refundable',
      subDetails: "",
      amount: '$0',
    },
  ];

  monthFilterArray = [
    { id : 1, value : 3, label : "3 months"},
    { id : 2, value : 6, label : "6 months"},
    { id : 3, value : 12, label : "12 months"},
  ]

  snapShotMonthFilterArray = [
    { id : 1, value : 1, label : "1 month"},
    { id : 2, value : 3, label : "3 months"},
    { id : 3, value : 6, label : "6 months"},
    { id : 4, value : 12, label : "All"},
  ]

  initialMonthArray =[
    { monthName: "Jan", value: 1, earningValue : 0 },
    { monthName: "Feb", value: 2, earningValue : 0 },
    { monthName: "Mar", value: 3, earningValue : 0 },
    { monthName: "Apr", value: 4, earningValue : 0 },
    { monthName: "May", value: 5, earningValue : 0 },
    { monthName: "Jun", value: 6, earningValue : 0 },
    { monthName: "Jul", value: 7, earningValue : 0 },
    { monthName: "Aug", value: 8, earningValue : 0 },
    { monthName: "Sep", value: 9, earningValue : 0 },
    { monthName: "Oct", value: 10, earningValue : 0 },
    { monthName: "Nov", value: 11, earningValue : 0 },
    { monthName: "Dec", value: 12, earningValue : 0 }
  ]

  initialSnapShotGraphData : SnapShotGraphData[] = [
    {
      id : 1,
      label: "Monthly Rentals",
      value: 0,
      progressTitle: "68",
      progressValue: 0,
      progressUpgrade: false,
      progressUpgradeCount: "+0",
    },
    {
      id : 2,
      label: "Avg. Monthly Revenue",
      value: 0,
      progressTitle: "$2.5",
      progressValue: 0,
      progressUpgrade: false,
      progressUpgradeCount: "+0",
    },
    {
      id : 3,
      label: "Avg. Rating",
      value: 0,
      progressTitle: "2.3",
      progressValue: 0,
      progressUpgrade: false,
      progressUpgradeCount: "+0",
    }
  ]

  getLastNMonthsData(array: MonthData[], count: number) {
    const currentMonthIndex: number = new Date().getMonth(); 
    
    const lastNMonths = [];
    
    for (let i = 0; i < count; i++) {
      const index = (currentMonthIndex - i + 12) % 12; 
      lastNMonths.unshift(array[index]); 
    }
  
    return lastNMonths;
  }

  getFilteredSnapShotData = (count : number) => {
    const currentMonth: number = new Date().getMonth()
    const generatedData : any[] = [];
    for (let i = 0; i < count; i++) {
      const index = (currentMonth - i + 12) % 12; 
      generatedData.push(index)
    }
    const modifiedArray = this.state.saveAllStaticsData.filter(item => generatedData.includes(item.attributes.month))

    return modifiedArray
  }

  handleMonthFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const inputValue = event.target.value as number
    this.setState({
      selectedMonthFilter: inputValue,
    },() => this.getAllHostEarningsData())
  }

  handleSnapShotFilterChange = (value : number) => {
    
    const filteredData = this.getFilteredSnapShotData(value)

    const rentalFilterdCount = filteredData.reduce((count: number, item: any) => {
      return count + item.attributes.rentals_count;
    }, 0);

    const revenueFilterdCount = filteredData.reduce((acc: number, item: any) => {
      return acc + item.attributes.earnings;
    }, 0)/ filteredData.length;

    const reviewFilteredCount = filteredData.reduce((accumulator: number, item: any) => {
      return accumulator + item.attributes.average_rating;
    }, 0)/ filteredData.length;

    const filteredSnapShotGraphData : SnapShotGraphData[] = [
      {
        id : 1,
        label: "Monthly Rentals",
        value: 0,
        progressTitle: rentalFilterdCount,
        progressValue: 0,
        progressUpgrade: false,
        progressUpgradeCount: "",
      },
      {
        id : 2,
        label: "Avg. Monthly Revenue",
        value: 0,
        progressTitle: `$${truthyNumberValue(revenueFilterdCount)}`,
        progressValue: 0,
        progressUpgrade: false,
        progressUpgradeCount: "",
      },
      {
        id : 3,
        label: "Avg. Rating",
        value:Number(reviewFilteredCount)/5*100,
        progressTitle: `${truthyNumberValue(reviewFilteredCount)}`,
        progressValue: 0,
        progressUpgrade: false,
        progressUpgradeCount: "",
      }
    ]
  
     this.setState({ snapShotSelectedFilter : value, snapShotGraphData : filteredSnapShotGraphData})
  }

  // Customizable Area End
}

// Customizable Area End