// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    LinearProgress,
    Button,
    Typography,
    OutlinedInput,
    TextareaAutosize,
    Grid,
    Checkbox,
    Radio,
    Divider
} from "@material-ui/core";

import AddHomeController, {
    Props,
  } from "./AddHomeController";
  
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { headerTextIcon, receivedIcon, timelineIcon ,payoutIcon, imageIcons, deleteIcon, congratsIcon, addHomeIcons3,addHomeIcons4, addHomeIcons1, addHomeIcons2, addHomeIcons5, addHomeIcons6,addHomeIcons8, addHomeIcons9, addHomeIcons10, addHomeIcons11, addHomeIcons7 } from "./assets";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import GoogleAutoComplete from "../../../components/src/GoogleAutoComplete.web";
import { apiKey } from "../../../components/src/asset";
import DragAndDrop from "../../../components/src/DragDropComponent.web";



class AddHome extends AddHomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  HomeFeatures = [
    {id : 1, icon: addHomeIcons1 , label: "Wifi"},
    {id : 2, icon: addHomeIcons2, label: "Air Condition"},
    {id : 3, icon: addHomeIcons3, label: "Washer"},
    {id : 4, icon: addHomeIcons4, label: "TV"},
    {id : 5, icon: addHomeIcons5, label: "Pool"},
    {id : 6, icon: addHomeIcons6, label: "BBQ Gril"},
    {id : 7, icon: addHomeIcons7, label: "Hot hub"},
    {id : 8, icon: addHomeIcons8, label: "Fire Pit"},
    {id : 9, icon: addHomeIcons9, label: "Exercide equipment"},
    {id : 10, icon: addHomeIcons6, label: "Indoor Fireplant"},
    {id : 11, icon: addHomeIcons10, label: "Beach Access"},
    {id : 12, icon: addHomeIcons11, label: "Pool Table"},
  ]


  handleAllAddHomeStepper = (step : number) => {
    switch(step) {
      case 1:
        return this.step1()
      case 2:
        return this.step2()
      case 3:
        return this.step3()
      case 4:
        return this.step4()
      case 5:
        return this.step5()
      case 6:
        return this.step6()    
    }
  }

  handleCommonBackFn = () => {
    return (
      <Box style={{ display: "flex", gap: "5px" }}>
        <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />
        <Typography 
        style={{
            fontSize: '14px',
            fontFamily: 'Plus Jakarta Sans',
            marginTop: '-2px',
            color: "#667085",
            cursor: "pointer"
        }}
        data-test-id="backLink" 
          onClick={() => this.handlePreviousHomeStep()}
        > Back</Typography>
      </Box>
    )
  }

  handleCommonHeaderText = (headerText: string) => {
    return <Typography className="commonHeaderText">{headerText}</Typography>
  }

  handleCommonBtn = () => {
    return(
      <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
          {this.state.activeHomeStepperStep !== 1 && <Button data-test-id="backBtn" onClick={this.handlePreviousHomeStep} className="commonBackBtn">Back</Button>}
          <Button data-test-id="nextBtn" onClick={() => this.handleNextHomeStep()} className="commonContinueBtn">Continue</Button>
      </Box>
    )
  }

   step1 = () => {
     return (
        <Step1>
            {this.handleCommonHeaderText("Select Home")}

            <Box className="gridHomeBox">
               <Grid container spacing={3}>
                {this.state.homeTypesData.map((arrayVal) =>
                  <Grid key={arrayVal.id} item md={4} sm={6} xs={12}>
                    <Box className="homeBox">
                       <Box style={{ margin :"auto", textAlign: "center"}}>
                          <img style={{ width: 56, height: 56}} src={arrayVal.icon} alt="previewImg"/>
                          <Typography className="homeText">{arrayVal.label}</Typography>
                       </Box>
                       <Box style={{ position: "absolute", right: "10"}}>
                         <Radio 
                         checked={arrayVal.id.toString() === this.state.selectedHomeId} 
                         onChange={this.handleSelectedHome}  
                         data-test-id="selectedHome"
                         value={arrayVal.id}
                          name={arrayVal.name}
                         color="primary" />
                       </Box>
                    </Box>
                  </Grid>
                )}
                  
               </Grid>
            </Box>
            {this.handleCommonBtn()}
        </Step1>
     )
   }

   step2 = () => {
    return (
       <Step2>
           {this.handleCommonBackFn()}
           {this.handleCommonHeaderText("Home Details")}
           
           <Box style={{ margin: "24px 0"}}>
             <Typography className="textFieldLabel"> Enter VIN</Typography>
             <OutlinedInput
              placeholder="Enter vin"
              value={this.state.homeVIN}
              onChange={this.handleChangeForHomeVinStep2}
              data-test-id="homeVIN"
              className="textBox"
              />
          </Box>

          <Box style={{ margin: "24px 0"}}> 
          <Box style={{ display: "flex", gap:8, alignItems: "center" }}>
            <Typography className="textFieldLabel">Home description </Typography>
            <HelpOutlineIcon />
          </Box>
             <TextareaAutosize
              placeholder="Tell guests what makes your car unique and why they’ll love driving it. New listings with descriptions of least 100 words are up to 3x more likely to get booked."
              className="textDescription"
              value={this.state.homeDescription}
              onChange={this.handleChangeForHomeDescriptionStep2}
              data-test-id="homeDesc"
              />
          </Box>

          <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
            <Button data-test-id="backBtn" onClick={this.handlePreviousHomeStep} className="commonBackBtn">Back</Button>
            <Button data-test-id="nextBtn" disabled={!this.state.showMap} style={{ backgroundColor : !this.state.showMap ? '#ccc' : '#3173E1' }} onClick={() => this.handleNextHomeStep()} className="commonContinueBtn">Continue</Button>
          </Box>

       </Step2>
    )
  }

  step3 = () => {
    return (
       <Step3>
           {this.handleCommonBackFn()}
           {this.handleCommonHeaderText("Guests Information")}


            <Box className="guestInfoCardBox">
              <Box style={{ background: "#EAECF0" }}>
                <Typography className="headerText3">What kind of space will guest have</Typography>
              </Box>
              <Box>
                {this.guestType.map((itemVal) =>
                  <Box key={itemVal.id} style={{ display: "flex", justifyContent: "space-between", padding: 10, margin: "0 15px", alignItems: "center", borderBottom: "1px solid #EAECF0" }}>
                    <Typography style={{
                      color: "#23395D",
                      fontSize: '16px',
                      fontWeight: 600,
                      fontFamily: 'Plus Jakarta Sans',
                    }}>
                      {itemVal.label}
                    </Typography>
                    <Radio
                      data-test-id="spaceType"
                      checked={itemVal.value === this.state.homeSpaceType}
                      name={itemVal.name}
                      value={itemVal.value}
                      onChange={this.handleSelectedHomeSpaceType}
                      color="primary"
                    />
                  </Box>



                )}

              </Box>
            </Box>

            <br /><br />

            <Box className="homeFeatureCardBox" style={{  }}>
               <Box style={{ padding: "0px 20px", margin: "10px 0"}}>
                  <Typography style={{ padding : 0}} className="headerText3"> Home Features</Typography>
                  <Divider style={{ margin :"10px 0"}} />
               </Box>
               <Box style={{ padding: "0 20px", margin: "10px 0"}}>
                <Grid container>
                  {this.HomeFeatures.map((item) =>
                    <Grid key={item.id} item md={3} sm={6} xs={12} spacing={4}>
                      <Box className="featuresBox">
                        <Box style={{
                          display: "flex",
                          gap: "5px", alignItems: "center"
                        }}>
                          <img src={item.icon} alt={item.label} />
                          <Typography className="featureText">{item.label}</Typography>
                        </Box>
                        <Box>
                          <Checkbox 
                          data-test-id="homeFeatureId"
                          checked={this.state.homeFeatures.includes(item.label)}
                          onChange={(event) => this.handleChangeOnHomeFeatures(event, item.label)}
                          color="primary" 
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
               </Box>
            </Box>

            <br /><br />

            <Box style={{ border: "1px solid #EAECF0", borderRadius: 12, width: "400px" }}>
              <Box style={{ background: "#EAECF0" }}>
                <Typography className="headerText3">How many guests can you welcome?</Typography>
              </Box>

              <Box>

                <Box className="guestBox">
                  <Typography className="guestHeaderTitle">
                    Guests
                  </Typography>
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Box data-test-id="decreaseFn" className="actionIcons" onClick={() => this.handleDecreaseCount(this.state.noOfGuest, "noOfGuest")}>-</Box>
                    <Typography className="guestCountText">{this.state.noOfGuest}</Typography>
                    <Box data-test-id="increaseFn" className="actionIcons" onClick={() => this.handleIncreseCount(this.state.noOfGuest, "noOfGuest")}>+</Box>
                  </Box>
                </Box>

                <Box className="guestBox">
                  <Typography className="guestHeaderTitle">
                    Beds
                  </Typography>
                  <Box style={{ display: "flex", gap: "10px" }}>
                  <Box className="actionIcons" data-test-id="decreaseFn" onClick={() => this.handleDecreaseCount(this.state.noOfBeds, "noOfBeds")}>-</Box>
                    <Typography className="guestCountText">{this.state.noOfBeds}</Typography>
                    <Box className="actionIcons" data-test-id="increaseFn" onClick={() => this.handleIncreseCount(this.state.noOfBeds, "noOfBeds")}>+</Box>
                  </Box>
                </Box>

                <Box className="guestBox">
                  <Typography className="guestHeaderTitle">
                    Bedrooms
                  </Typography>
                  <Box style={{ display: "flex", gap: "10px" }}>
                  <Box className="actionIcons" data-test-id="decreaseFn" onClick={() => this.handleDecreaseCount(this.state.noOfBedrooms, "noOfBedrooms")}>-</Box>
                    <Typography className="guestCountText">{this.state.noOfBedrooms}</Typography>
                    <Box className="actionIcons" data-test-id="increaseFn" onClick={() => this.handleIncreseCount(this.state.noOfBedrooms, "noOfBedrooms")}>+</Box>
                  </Box>
                </Box>

                <Box className="guestBox">
                  <Typography className="guestHeaderTitle">
                    Bathrooms
                  </Typography>
                  <Box style={{ display: "flex", gap: "10px" }}>
                  <Box className="actionIcons" data-test-id="decreaseFn" onClick={() => this.handleDecreaseCount(this.state.noOfBathrooms, "noOfBathrooms")}>-</Box>
                    <Typography className="guestCountText">{this.state.noOfBathrooms}</Typography>
                    <Box className="actionIcons" data-test-id="increaseFn" onClick={() => this.handleIncreseCount(this.state.noOfBathrooms, "noOfBathrooms")}>+</Box>
                  </Box>
                </Box>

                <Box className="guestBox">
                  <Typography className="guestHeaderTitle">
                    Parking space
                  </Typography>
                  <Box style={{ display: "flex", gap: "10px" }}>
                  <Box className="actionIcons" data-test-id="decreaseFn" onClick={() => this.handleDecreaseCount(this.state.noOfParkingSpace, "noOfParkingSpace")}>-</Box>
                    <Typography className="guestCountText">{this.state.noOfParkingSpace}</Typography>
                    <Box className="actionIcons" data-test-id="increaseFn" onClick={() => this.handleIncreseCount(this.state.noOfParkingSpace, "noOfParkingSpace")}>+</Box>
                  </Box>
                </Box>


               

              </Box>
            </Box>

        
           {this.handleCommonBtn()}
       </Step3>
    )
  } 

  showInitialImageSection = () => {
    return (
      <Box style={{ width: this.state.addHomePhotos ? "350px" : "auto" }} className="boxHomePhotos1">
        <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          <img src={imageIcons} alt="img" />
          <Button data-test-id="addPhotos" onClick={() => this.handleHomeToggleAddPhostosBtn()} variant="outlined" style={{ borderRadius: 20, textTransform: "none", margin: "10px 0", color: "#3173E1" }}> Add Photos</Button>
        </Box>
      </Box>
    )
  }

  showDragDropImageSection = () => {
    return (
      <DragAndDrop data-test-id="dragDropComponent" onDropFiles={this.handleHomeDragDrop} />
    )
  }

  showImagePreviewSection = () => {
    return (
      <Box style={{ margin: "25px 0" }}>
        <Grid container spacing={3}>
          {this.state.uplodedHomePhotosURL.map((url, index) => (
            <Grid item md={4} sm={6} xs={12} style={{ position: "relative" }}>
              <img data-test-id="deleteUploadedPhoto" onClick={() => this.handleDeleteUploadedPhotos(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
              <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
            </Grid>
          ))}
        </Grid>

        <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
          <Typography> Add More <span data-test-id="addMoreText" onClick={() => this.handleInputRefCall()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
        <input type="file" data-test-id={"addMorePhotos"} onChange={this.handleHomePhotosChange} ref={this.inputFileRef} multiple style={{ display: "none" }} />

      </Box>
    )
  }

  step4 = () => {
    return (
       <Step4>
           {this.handleCommonBackFn()}
           {this.handleCommonHeaderText("Home Photos")}

          <Typography className="getReadyHomeText"> Get Ready </Typography>
          <Typography className="getReadyHomeSubText">We will help you take the best photos to make your listing stand out to guests. </Typography>

          {this.state.addHomePhotos && this.showInitialImageSection()}
          {!this.state.addHomePhotos && this.state.uplodedHomePhotosURL.length === 0 && this.showDragDropImageSection()}
          {!this.state.addHomePhotos && this.state.uplodedHomePhotosURL.length > 0 && this.showImagePreviewSection()}
          {!this.state.addHomePhotos && <Typography className="getReadySubText"><li> Images ( .jpg,.gif, .png, up to 10 MB, no more than 40000 px in any dimension) </li></Typography>}

           {this.handleCommonBtn()}
       </Step4>
    )
  }

  step5 = () => {
    return (
        <Step5>
            {this.handleCommonBackFn()}
            {this.handleCommonHeaderText("Link Payouts")}

            <Box>
                <img src={payoutIcon} alt="src" />
            </Box>

            <Typography className="setUpTextHome">Set up Stripe Account</Typography>

            <p style={{ color: "#475467"}}>To receive your earnings, you need to set up an account with Stripe, our payment provider.</p>
            <p style={{ color: "#475467"}}>In the next step, Stripe will verify your identity and account information.</p>
            <p style={{ color: "#475467"}}>Once your account is verified, you’ll provide your direct deposit information</p>

            <Box style={{ display: "flex", gap: 20 }}>
                <Button className="saveFinishBtn" data-test-id="saveAndFinish" onClick={() => this.addHome()}>Save & Finish Later</Button>
                <Button className="linkPayoutBtn">Link stripe payouts</Button>

            </Box>

        </Step5>
    )
  }

  step6 = () => {
    return (
      <Step6>
        <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
          <img src={congratsIcon} style={{ height: 100, width: 80, margin: "auto" }} alt="congrats" />

          <Typography className="congratsHomeText">Congratulations</Typography>
          <Box style={{ padding: "10px 0" }}>
            <Typography className="congratsSubText">You are all set to go for your trip. If you have any concern or questions,
            </Typography>
            <Typography className="congratsSubText">please do not hesitate to reach out.</Typography>
          </Box>
          

          <Button
            onClick={this.navigateToListingPage}
            variant="contained"
            data-test-id="navToDashboard"
            style={{
              margin: "20px auto",
              borderRadius: 20, color: "white",
              background: "#3173E1",
              textTransform: "none",
              fontWeight: 600,
              width: "180px"
            }}
          >
            Start Your Trip
          </Button>
        </div>
      </Step6>
    )
  }

  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
            <AddHomeMainWrapper>
            

          <Grid container spacing={5}>
            <Grid item md={8}>
              <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                {this.addHomeStepperSteps.map((label) => (
                  <div>
                    <Box className={this.handleAddHomeStepperCSS(label.activeKey, this.state.activeHomeStepperStep)} key={label.id}>
                      <p style={{ textAlign: "center", margin: "25% 0" }}>{label.id}</p>
                    </Box>
                    <p className="stepperTextAddHome">{label.label}</p>
                  </div>
                ))}
              </Box>

              <Box style={{ margin: "30px 0" }}>
                <LinearProgress className="progressBarAddHome" value={Math.floor((this.state.activeHomeStepperStep / this.addHomeStepperSteps.length) * 100)} variant="determinate" />
              </Box>

              {this.handleAllAddHomeStepper(this.state.activeHomeStepperStep)}


            </Grid>
            <Grid item md={4}>
              {this.state.activeHomeStepperStep === 2 && 
                <Box style={{ background: "#E3EDFD", padding: 20, borderRadius: 12 }}>

                  <Box style={{background: "white", border: "1px solid #EAECF0",  borderRadius: 12, padding: "16px 14px" }}>
                    <Typography className="homeInfoText" >Home pricing</Typography>
                    <Divider style={{ margin: "10px 0" }} />

                    <Box style={{ display: "flex", flexDirection: "column", gap : "15px"}}>
                       <img src={headerTextIcon} />
                       <img src={timelineIcon} />
                       <img src={receivedIcon} />
                    </Box>
                  </Box>

                  <Box style={{ margin: "10px 0" }}>
                    <Typography className="homeInfoText">Where is your home located? *</Typography>
                    <GoogleAutoComplete data-test-id="addressField"  locationFields={this.handleAddressInfo}/>
                  </Box>

                  {this.state.showMap &&
                    <Box>
                      <Typography style={{ margin: "10px 0" }} className="homeInfoText" >Is the pin in the right spot?</Typography>
                      <Box>
                        <iframe
                          width="100%"
                          height="180"
                          frameBorder="0"
                          style={{ borderRadius: 12 }}
                          referrerPolicy="no-referrer-when-downgrade"
                          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${this.state.addressInfo.latitide},${this.state.addressInfo.longitude}`}
                          allowFullScreen
                        ></iframe>
                      </Box>

                    </Box>
                  }
                </Box>

              }
            </Grid>
          </Grid>

             
            </AddHomeMainWrapper>
         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default AddHome;

const AddHomeMainWrapper =  styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "@media(max-width:1300px)": {
      margin : "40px 30px",
    },
    "&  .AddHomeStepper": {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "white",
        border: "1px solid #D0D5DD",
        margin: "auto"
      },
      "&  .AddHomeActiveStepper": {
        width: "40px",
        background: "#3173E1",
        color:"white",
        borderRadius: "50%",
        height: "40px",
        border: "1px solid #3173E1",
        margin: "auto"
      },
      "&  .AddHomeCurrentStepper": {
        width: "40px",
        height: "40px",
        color:"#3173E1",
        borderRadius: "50%",
        background: "#E3EDFD",
        border: "1px solid #3173E1",
        margin: "auto"
      },
      "& .stepperTextAddHome" : {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '12px',
          fontWeight: 700,
          textAlign: 'center'
      },
      "& .progressBarAddHome": {
        height: "10px",
        borderRadius: "10px",
        backgroundColor:"#E3EDFD",
      },
      "& .progressBarAddHome .MuiLinearProgress-barColorPrimary": {
        backgroundColor:"#3173E1"
      },
      "& .commonHeaderText": {
          textAlign: 'left',
          fontSize: '30px',
          fontWeight: 600,
          fontFamily: 'Plus Jakarta Sans',
          color:"#23395D",
          margin: "25px 0"
      },
      "& .commonContinueBtn" : {
        width: '208px',
        height: '48px',
        gap: '8px',
        backgroundColor: '#3173E1',
        textTransform: "none",
        borderRadius: '12px',
        color: 'white',
        margin: "15px 0",
        padding: '14px 16px',
        fontWeight: "bold",
      },
      "& .commonBackBtn" : {
        borderRadius: '12px',
        gap: '8px',
        color: '#475467',
        height: '48px',
        border: "1px solid #D0D5DD",
        fontWeight: "bold",
        backgroundColor: 'white',
        padding: '14px 16px',
        margin: "15px 0",
        width: '208px',
        textTransform: "none"
      },
      "& .homeInfoText" : {
        fontSize: "16px",
        fontWeight: "600",
        color:"#22395D",
        fontFamily: 'Plus Jakarta Sans',
      },
      "& .locationFieldHome" : {
        background: "white",
        height: "45px",
        borderRadius: "12px",
        margin: "5px 0"
      }
})

const Step5 = styled(Box)({
    "& .setUpTextHome" : {
        margin: "15px 0",
        fontWeight: 600,
        fontSize: '20px',
        textAlign: 'left',
        color: "#23395D"
      }, 

      "& .linkPayoutBtn" : {
        width: '208px',
        gap: '8px',
        padding: '14px 16px',
        backgroundColor: '#3173E1',
        borderRadius: '12px',
        margin: "15px 0",
        color: 'white',
        height: '48px',
        fontWeight: "bold",
        textTransform: "none"
      },
      "& .saveFinishBtn" : {
        gap: '8px',
        width: '208px',
        padding: '14px 16px',
        height: '48px',
        color: '#475467',
        backgroundColor: 'white',
        border: "1px solid #D0D5DD",
        fontWeight: "bold",
        margin: "15px 0",
        borderRadius: '12px',
        textTransform: "none"
      },
})

const Step4 = styled(Box)({
  "& .getReadyHomeSubText": {
    fontWeight: 500,
    fontSize: "20px",
    color: "#475467"
  }, 
  "& .boxHomePhotos1" : {
    height: "300px",
    display: "flex",
    border: "1px dashed #3173E1",
    borderRadius: "12px",
    margin: "20px 0"
  }, 
  "& .getReadyHomeText": {
    fontWeight: 600,
    fontSize: "20px",
    color: "#23395D"
  },
})

const Step2 = styled(Box)({
   "& .textFieldLabel" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    textAlign: 'left',
    color:"#23395D",
    margin: "10px 0"
   },
   "& .textBox" : {
    height: '44px',
    width: '669px',
    gap: '8px',
    borderRadius: '12px',
    border:"1px solid #D0D5DD",
    
   }, 
   "& .textDescription" : {
    padding: "20px",
    height: '185px !important',
    width: '669px',
    gap: '8px',
    borderRadius: '12px',
    border:"1px solid #D0D5DD"
   }
})

const Step3 = styled(Box)({
  "@media(max-width:1300px)": {
    margin : "40px 30px",
    maxWidth : "100%"
  },
   "& .headerText3" : {
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Plus Jakarta Sans',
    textAlign: 'left',
    color:"#23395D",
    padding: "24px 16px"
   },
   "& .actionIcons" : {
    width: 24,
    height: 24,
    background: "#D6E3F9",
    textAlign: "center",
    cursor: "pointer"
   }, 
   "& .guestCountText" : {
    color: "#23395D",
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Plus Jakarta Sans',
    width: "24px",
    textAlign: "center",
   },
   "& .guestHeaderTitle" : {
     color: "#23395D",
     fontSize: '16px',
     fontWeight: 600,
     fontFamily: 'Plus Jakarta Sans',
   },
   "& .guestBox" : {
    display: "flex", 
    justifyContent: "space-between", 
    padding: 10, 
    margin: "0 15px", 
    alignItems: "center", 
    borderBottom: "1px solid #EAECF0"
   }, 
   "& .featuresBox" : {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: '8px',
    border: '1px solid transparent',
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    padding: "5px",
    margin: "10px 30px 10px 0"
   }, 
   "& .featureText" : {
      padding: "0 10px",
      fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'left'
   },
   "& .guestInfoCardBox" : {
    border: "1px solid #EAECF0",
    borderRadius: 12, 
    width: "400px" ,
    "@media(max-width:1000px)": {
       width : "max-content"
    },
   },
   "& .homeFeatureCardBox" : {
    border: "1px solid #EAECF0", 
    borderRadius: 12, 
    width: "800px",
    "@media(max-width:900px)": {
      width : "auto"
   },
   }

})

const Step1 = styled(Box)({
  "& .homeBox" : {
    height: 150,
    width: 180,
    border: "1px solid #0F73EE",
    borderRadius: 8,
    display: "flex",
    flexDirection : "column",
    position: "relative",
    "@media(max-width:960px)": {
      width: "auto",
    }
  },
  "& .homeText" : {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Plus Jakarta Sans',
    color:"#23395D",
    margin:"5px 0"
  },
  "& .gridHomeBox" : {
    width : 600,
    "@media(max-width:960px)": {
      width: "auto",
    }
  }
})

const Step6 = styled(Box)({
  "& .congratsHomeText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontWeight: 600,
    color: "#23395D",
    textAlign: "center",
    margin: "10px 0"
  },
  "& .congratsSubText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: "#475467",
    textAlign: "center",
  }
})

// Customizable Area End