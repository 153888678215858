// Customizable Area Start
import React from "react";
import GoogleCalendarComponent from "../../../components/src/GoogleCalendar";

import GoogleCalendarController, {
    Props,
  } from "./GoogleCalendarController";


class GoogleCalendar extends GoogleCalendarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
         <GoogleCalendarComponent 
           syncToCalendar={this.props.isGoogleCalendarSync}
           bookingDates={this.props.bookingInfo}  
           navToHome={this.navigateToHome} 
           data-test-id="googleCalendarComponent"
        />
      </div>
    );
    // Customizable Area End
  }
}

export default GoogleCalendar;

// Customizable Area End