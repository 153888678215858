import React, { useState, useRef } from "react";
import {Box, styled, Typography} from "@material-ui/core"

interface DragAndDropProps {
  onDropFiles: (files: File[]) => void;
  accept?: string;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
  onDropFiles,
  accept,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);

    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      onDropFiles(Array.from(files)); 
    }
  };

  const handleClickUpload = () => {
    inputFileRef.current?.click();
  };

  return (
      <DragDropWrapper>
          <div
              className={`dropZone ${isDragActive ? "dragActive" : ""}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleClickUpload}
          >
              <input
                  type="file"
                  ref={inputFileRef}
                  accept={accept}
                  style={{ display: "none" }}
                  onChange={(e) => {
                      const files = e.target.files;
                      if (files && files.length > 0) {
                          onDropFiles(Array.from(files)); // Handle file input change
                      }
                  }}
              />
              {isDragActive ? (
                  <Box className="dragBox">
                      <Typography className="dragBoxText">Drop files here</Typography>
                  </Box>
              ) : (
                  <Box className="dragBox">
                      <Typography className="dragBoxText">Drag and drop or  <span style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer", fontWeight: 600 }}> browse </span> files</Typography>
                  </Box>
              )}
          </div>
      </DragDropWrapper>
  );
};

export default DragAndDrop;


const DragDropWrapper = styled(Box)({
    "& .dropZone" : {
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
        cursor: 'pointer',
        height: "300px",
        border: "1px dashed #3173E1",
        borderRadius: "12px",
        display: "flex",
        margin: "20px 0" 
      },
      "& .dragActive" : {
        backgroundColor: '#e3f2fd',
        borderColor: '#3173E1',
      },
      "& .dragBox" : {
        display: "flex", 
        flexDirection: "column", 
        margin: "auto"
      },
      "& .dragBoxText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: "16px",
        color: "#23395D"
      },
})