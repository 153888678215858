// Customizable Area Start
import React from "react";
import CommonReservationInfo from "../../../components/src/CommonReservationInfo.web"
import {
  styled,
  Box,
  Typography,
  Grid,
  Button
} from "@material-ui/core"
import { deleteIcon, imageIcons } from "./assets";
import Loader from "../../../components/src/Loader.web";
import DragAndDrop from "../../../components/src/DragDropComponent.web";

import HostReservationCheckoutController, {
    Props,
  } from "./HostReservationCheckoutController";

class HostReservationCheckout extends HostReservationCheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
          <CommonReservationInfo
            navigation={this.props.navigation}
            id="CheckoutPage"
            pageTitle="Add Photos"
          >
          {this.state.loading ?
            <Loader loading={true} />
            :
            <CheckOutWrapper>
              <Box>
                <Typography className="titleText">Add new photos</Typography>
                <Typography className="subTitleText">Please take at least 10 photos to document the car's condition. Include interior, exterior, rims, odometer and fuel level photos.</Typography>
              </Box>

              <Box>
                {this.state.uploadedPhotosUrl.length === 0 &&
                  <Box >
                    {this.state.addPhotos &&
                      <Box className="imagesBox">
                        <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                          <img src={imageIcons} alt="img" />
                          <Button data-test-id="addPhotos" onClick={() => this.handleToggleAddPhostos()} variant="outlined" style={{ borderRadius: 20, textTransform: "none", margin: "10px 0", color: "#3173E1" }}> Add Photos</Button>
                        </Box>
                      </Box>
                    }

                    {!this.state.addPhotos && <DragAndDrop data-test-id="dragDropComponent" onDropFiles={this.handleFileDragSelection} />}
                  </Box>
                }

                {this.state.uploadedPhotosUrl.length > 0 &&
                  <Box style={{ margin: "25px 0" }}>
                    <Grid container spacing={3}>
                      {this.state.uploadedPhotosUrl.map((url, index) => (
                        <Grid item md={4} sm={6} xs={12} style={{ position: "relative" }}>
                          <img data-test-id="deleteUploadedPhoto" onClick={() => this.handleDeletePhotos(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
                          <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
                        </Grid>
                      ))}
                    </Grid>

                    <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
                      <Typography> Add More <span data-test-id="addMoreText" onClick={() => this.handleRefCall()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
                    <input type="file" data-test-id={"addMorePhotos"} onChange={this.handleCataloguesPhotosChange} ref={this.fileRef} multiple style={{ display: "none" }} />

                  </Box>}

                <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                  <Button data-test-id="backBtn" onClick={this.handleNavigationToBackPage} className="backBtn">Back</Button>
                  <Button data-test-id="nextBtn" onClick={this.saveImages} className="nextBtn">Continue</Button>
                </Box>
              </Box>
            </CheckOutWrapper>
          }
          </CommonReservationInfo>

      </div>
    );
    // Customizable Area End
  }
}

export default HostReservationCheckout;

const CheckOutWrapper = styled(Box)({
  "& .titleText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    color: '#23395D'
  },
  "& .subTitleText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467'
  },
  "& .imagesBox" : {
    height: "300px",
    border: "1px dashed #3173E1",
    borderRadius: "12px",
    display: "flex",
    margin: "20px 0"
  },
  "& .nextBtn" : {
    width: '150px',
    margin: "15px 0",
    textTransform: "none",
    gap: '8px',
    backgroundColor: '#3173E1',
    height: '48px',
    borderRadius: '12px',
    border: "1px solid #D0D5DD",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    color: 'white',
    padding: '14px 16px',
  },
  "& .backBtn" : {
    borderRadius: '12px',
    width: '150px',
    backgroundColor: 'white',
    padding: '14px 16px',
    margin: "15px 0",
    textTransform: "none",
    gap: '8px',
    height: '48px',
    color: '#475467',
    border: "1px solid #D0D5DD",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
  },


})

// Customizable Area End