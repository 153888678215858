// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Button,
    Grid,
 } from "@material-ui/core"
 import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
 import Footer from "../../../components/src/Footer.web";
 import Loader from "../../../components/src/Loader.web";
 import PricingCard from "../../../components/src/PricingCard.web";
 import DragAndDrop from "../../../components/src/DragDropComponent.web";
 
 import { deleteIcon, starIcon } from "./assets";

import ListingSettingsController, {
    Props,
  } from "./ListingSettingsController";

class ListingSettings extends ListingSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderPhotosBox = () => {
      return (
          this.state.uploadedCatalougePhotos.length > 0 ?
              <Box className="uploadedImagesBox">
                  <Box style={{ margin: "25px 0" }}>
                      <Grid container spacing={3}>
                          {this.state.uploadedCatalougePhotos.map((url, index) => (
                              <Grid item md={4} sm={6} xs={12} style={{ position: "relative" }}>
                                  <img data-test-id="deleteUploadedPhoto" onClick={() => this.deleteUploadedPhotos(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
                                  <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
                              </Grid>
                          ))}
                      </Grid>

                      <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
                          <Typography> Add More <span data-test-id="addMoreText" onClick={() => this.handleRefCall()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
                      <input type="file" data-test-id={"addMorePhotos"} onChange={this.changeImages} ref={this.imgRef} multiple style={{ display: "none" }} />

                  </Box>
              </Box>
              :
              <>
                <DragAndDrop data-test-id="dragDropComponent" onDropFiles={this.handleDragDropPhostos} />
                <Typography className="imgNoteText"><li> Images ( .jpg,.gif, .png, up to 10 MB, no more than 40000 px in any dimension) </li></Typography>
              </>


    )
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />

            {this.state.loader ?
                <Loader loading={true} />
                :
                <ListingWrapper>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <Typography className="catalougeTypeText">Your {this.state.catalougeType}</Typography>

                            <Box className="infoBox">
                                <img className="infoImg" src={this.state.catalougeInfo.catalougeImg} />
                                <Box>
                                    <Typography className="infoHeader">{this.state.catalougeInfo.catalougeName}</Typography>
                                    <Box style={{ display: "flex", gap: 10}}>
                                        <img src={starIcon} />
                                        <Typography className="reviewText"> {this.state.catalougeInfo.catalougeReviews.count} {this.state.catalougeInfo.catalougeReviews.rating} reviews </Typography>
                                    </Box>
                                </Box>

                            </Box>

                            <Box className="photoBox" style={{ margin : "15px 0"}}>
                                <Typography className="infoSubHeader">Add Photos</Typography>
                                <Typography className="noteText">Please take up to 9 photos to document the {this.state.catalougeType}'s condition. Include interior and exterior. The Hosts photos will show up here also.</Typography>
                                

                                {this.renderPhotosBox()}
                           
                                
                            </Box>

                            <Box className="descriptionBox">
                                <Typography className="infoSubHeader">{this.state.catalougeType} Description</Typography>
                                <Box className="descriptionTextBox">
                                    {this.state.catalougeDescription}
                                </Box>
                            </Box>

                            <Box className="arrayBox">
                                <Grid container spacing={3}>
                                    {this.settingArray.map(arrayVal =>
                                        <Grid item md={3} key={arrayVal.id}>
                                            <Box className="mainBox" data-test-id="navToPage" onClick={() => this.navigateToPage(arrayVal.navigation)}>
                                                <img src={arrayVal.icon} />
                                                <Typography className="titleText">{arrayVal.title}</Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>

                            </Box>

                            <Button className="saveBtn" data-test-id="saveBtn" onClick={this.updateCatalougeInfo}>Save</Button>
                        </Grid>
                        <Grid item md={4}>
                            <PricingCard catalougeId={this.state.catalougeId} navigation={this.props.navigation} id={"ListingSetting"} />
                        </Grid>

                    </Grid>
                </ListingWrapper>
            }
         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default ListingSettings;

const ListingWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .catalougeTypeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        color: '#101828'
    },
    "& .infoBox": {
        display: "flex",
        gap: "20px",
        margin: "20px 0"
    },
    "& .infoImg" : {
        height: "115px",
        width: "130px",
        borderRadius: "12px"
    },
    "& .infoHeader" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '30px',
        color: '#23395D'
    },
    "& .reviewText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '16px',
        color: '#23395D'
    },
    "& .infoSubHeader" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '20px',
        color: '#23395D'
    },
    "& .noteText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 400,
        fontSize: '14px',
        color: '#475467',
        marginBottom : "20px"
    },
    "& .descriptionTextBox" : {
        height: 100,
        margin: "15px 0",
        padding: "10px 15px",
        fontFamily: 'Plus Jakarta Sans',
        color: "#475467",
        borderRadius: "12px",
        border: "1px solid #E7E7E7",
        fontSize: "14px",
        fontWeight: 600,
        maxHeight: 110,
        overflowY: "scroll",
        scrollbarWidth: "none"
      },
      "& .mainBox" : {
        border: "1px solid #E7E7E7",
        borderRadius: "12px",
        boxShadow: "0px 4px 5px 0px #0000000D",
        textAlign: "center",
        padding: "20px 15px",
        minHeight: 85,
        cursor: "pointer"
      },
      "& .titleText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '16px',
        color: '#23395D',
        maxWidth: 150
      },
      "& .arrayBox" : {
        margin:"10px 0"
      },
    "& .boxPhotos": {
        height: "200px",
        border: "1px dashed #3173E1",
        borderRadius: "12px",
        display: "flex",
    },
    "& .dragDropText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '16px',
        color: '#23395D',
    },
    "& .imgNoteText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '16px',
        color: '#475467'
    },
    "& .saveBtn" : {
        height: 40,
        width: 90,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        textTransform: "none",
        fontWeight: 600,
        background: "#3173E1",
        borderRadius: "12px",
        color: 'white',
        margin: "20px 0"
      },

    
})

// Customizable Area End