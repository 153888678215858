
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_catalogue/pre_catalogue_booking";
exports.getSavedCardsApiEndPoint = "bx_block_stripe_integration/payment_methods/fetch_user_cards";
exports.createBookSlot = "bx_block_calendar/booked_slots";
exports.productAPiMethod = "GET";
exports.productAPiPostMethod = "POST";
exports.getReviewsEndPoints="bx_block_catalogue/catalogue_review"
exports.getAllPoliciesEndPoints="bx_block_profile/policies"
exports.productApiContentType = "application/json";
exports.getUserInfoApiEndPoint= "account_block/accounts/get_user"
exports.verifyOtpApiEndpoint="account_block/accounts/confirm_otp"
exports.signUpApiEndPoint="account_block/accounts"
exports.apiGETMethod = "GET";
exports.apiPOSTMethod = "POST";
exports.createPaymentIntentApiEndPoint="bx_block_stripe_integration/payment_intents"
exports.confirmPaymentIntentApiEndPoint= (id) => `bx_block_stripe_integration/payment_intents/${id}/confirm`
// Customizable Area End
