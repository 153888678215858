import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { 
    Box, 
    Button,
    DialogActions,
    OutlinedInput,
    Typography,
    TextField
} from '@material-ui/core';
import { styled } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import { CardDetails, SelectedCountry, SelectedState, SelectedCity } from "./TypeInterfaces.web";
import { getAllCountries, getStatesOfCountry, getAllCitiesOfStateAndCountries } from "./asset";
import { ICountry, IState, ICity } from 'country-state-city'

interface CardComponentProps {
    cardFlow : string,
    addCardDetail: (payload: CardDetails) => void;
    handleClose: () => void;
}

const CardComponent: React.FC<CardComponentProps> = (props) => {

  const initialSelectedCountry : ICountry = {
    name: "",
    phonecode: "",
    isoCode: "",
    flag: "",
    currency: "",
    latitude: "",
    longitude: "",
  }

  const initialSelectedState : IState = {
    name: "",
    isoCode: "",
    countryCode: "",
  }

  const initialSelectedCity : ICity = {
    name: "",
    countryCode: "",
    stateCode: "",
  }

  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState<string>("");
  const [nameErr, setNameErr] = useState<string>("");
  const [numberErr, setNumberErr] = useState<string>("");
  const [dateErr, setDateErr] = useState<string>("");
  const [cvvErr, setCvvErr] = useState<string>("");

  const [message, setMessage] = useState<any>(null);
  const [countryOptions, setCountryOptions] = useState<ICountry[]>([])
  const [stateOptions, setStatesOptions] = useState<IState[]>([])
  const [cityOptions, setCityOptions] = useState<ICity[]>([])

  const [selectedCountry, setSelectedCountry] = useState<ICountry>(initialSelectedCountry)
  const [selectedState, setSelectedState] = useState<IState>(initialSelectedState)
  const [selectedCity, setSelectedCity] = useState<ICity>(initialSelectedCity)


  const [cardDetails, setCardDetails] = useState<{ [key: string]: string }>({
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: ""
  });
  const [cardDetailsErrors, setCardDetailsErrors] = useState<{ [key: string]: boolean }>({
    street: false,
    city: false,
    state: false,
    zip_code: false,
    country: false
  });

  const clearStripeModal = () => {
    const cardNo = elements?.getElement(CardNumberElement);
    const cardExpiry = elements?.getElement(CardExpiryElement);
    const cardCVV = elements?.getElement(CardCvcElement);
    cardNo?.clear();
    cardCVV?.clear();
    cardExpiry?.clear();
    setName("");
  };

  const isNameValid = (): boolean => {
    if (name.length < 1) {
      setNameErr("Your name is incomplete.");
      return false;
    } else if (!(/^[A-Za-z]+( [A-Za-z]+)*$/i.test(name)) || name.trim() === "") {
      setNameErr("Your name is invalid.");
      return false;
    } else {
      setNameErr("");
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if(!handleAllValidationOnCardSave()) {
        return false;
     }
  
    const nameValid = isNameValid();
    if (numberErr !== "" || dateErr !== "" || cvvErr !== "") {
      if (!nameValid) {
        return;
      }
    }
  
    const cardElement = elements.getElement(CardNumberElement);
    
    if (!cardElement) {
      setMessage("Card number element not found.");
      return;
    }
  
    const { token, error } = await stripe.createToken(cardElement);
  
    if (!nameValid || numberErr || dateErr || cvvErr) {
      return;
    }
  
    if (error) {
      setMessage(error.message);
    } else {
        if( token && token.id) {
            const payload: CardDetails = {
                card_token: token.id,
                street: cardDetails.street, 
                city: cardDetails.city,
                state: cardDetails.state,
                zip_code: cardDetails.zip_code,
                country: cardDetails.country,
            }

            props.addCardDetail(payload)
        }
    }
  };
  

  const handleCancelClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setNameErr("");
    setNumberErr("");
    setDateErr("");
    setCvvErr("");
    props.handleClose();
    clearStripeModal();
  };

  const handleChangeOfAddCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value;
    const formName = event.target.name;
  
    // Validate input
    if (formValue.trim() === "") {
      setCardDetailsErrors(prevErrors => ({ ...prevErrors, [formName]: true }));
    } else {
      setCardDetailsErrors(prevErrors => ({ ...prevErrors, [formName]: false }));
    }
  
    // Update card details
    setCardDetails(prevDetails => ({ ...prevDetails, [formName]: formValue }));
  };
  
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (
            !(
                (event.key >= '0' && event.key <= '9') ||
                event.key === 'Backspace' ||
                event.key === 'Delete' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    }
  
    const handleErrorFields = (checkKey: boolean, errMsg: string) => {
        return checkKey && <div className="textLabel" style={{ color: "red", marginBottom: "7px" }}>{errMsg}</div>
    }

    const handleAllValidationOnCardSave = () => {
        const checkValidation = {
            street: false,
            city: false,
            state: false,
            zip_code: false,
            country: false
        };

        if (cardDetails.street.trim() === "") {
            checkValidation.street = true;
        }
        if (cardDetails.city.trim() === "" || selectedCity.name.trim() === "") {
            checkValidation.city = true;
        }
        if (cardDetails.state.trim() === "" || selectedState.name.trim() === "") {
            checkValidation.state = true;
        }
        if (cardDetails.zip_code.trim() === "") {
            checkValidation.zip_code = true;
        }
        if (cardDetails.country.trim() === "" || selectedCountry.name.trim() === "") {
            checkValidation.country = true;
        }

        setCardDetailsErrors(checkValidation);

        return Object.values(checkValidation).every((value) => value === false);
    };

    const handleSelectForCountry = (event : any, countryVal : ICountry) => {

      if(selectedCountry.isoCode !== countryVal.isoCode) {
         setSelectedState(initialSelectedState)
         setSelectedCity(initialSelectedCity)
      } 
      
      const AllStates = getStatesOfCountry(countryVal.isoCode)

      setSelectedCountry(countryVal)
      setStatesOptions(AllStates)

      if (countryVal.name.trim() === "") {
        setCardDetailsErrors(prevErrors => ({ ...prevErrors, "country": true }));
      } else {
        setCardDetailsErrors(prevErrors => ({ ...prevErrors, "country": false }));
      }
      setCardDetails(prevDetails => ({ ...prevDetails, "country": countryVal.isoCode }));
    }

    const handleSelectForState = (event : any, stateVal : IState) => {

      if(selectedState.isoCode !== stateVal.isoCode) {
        setSelectedCity(initialSelectedCity)
     } 

      const AllCities = getAllCitiesOfStateAndCountries(stateVal.countryCode, stateVal.isoCode)
      
      setCityOptions(AllCities)
      setSelectedState(stateVal)

      if (stateVal.name.trim() === "") {
        setCardDetailsErrors(prevErrors => ({ ...prevErrors, "state": true }));
      } else {
        setCardDetailsErrors(prevErrors => ({ ...prevErrors, "state": false }));
      }
      setCardDetails(prevDetails => ({ ...prevDetails, "state": stateVal.name }));

    }

    const handleSelectForCity = (event : any, cityVal : ICity) => {
      setSelectedCity(cityVal)

      if (cityVal.name.trim() === "") {
        setCardDetailsErrors(prevErrors => ({ ...prevErrors, "city": true }));
      } else {
        setCardDetailsErrors(prevErrors => ({ ...prevErrors, "city": false }));
      }
      setCardDetails(prevDetails => ({ ...prevDetails, "city": cityVal.name }));
    }


    useEffect(() => {
        const countyrArr = getAllCountries()
        setCountryOptions(countyrArr)
    }, [countryOptions])
  
  return (
    <R1Form>
      {props.cardFlow === "profile" ?
        <form className={"r1form"} id="payment-form" onSubmit={handleSubmit}>
          <Typography className="subHeaderText">Card Information</Typography>

          <Box mb="20px" display="flex" flexDirection="column">
            <label className="labelField">Card Holder Name</label>
            <input
              className="inputField"
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameErr("");
                setMessage("");
              }}
              onBlur={() => isNameValid()}
            />
            <div className="error">{nameErr}</div>
          </Box>
          <Box mb="20px">
            <label className="labelField">Card Number</label>
            <CardNumberElement
              id='cardNumberId'
              className="inputField"
              options={{ showIcon: true }}
              onChange={(e) => {
                setNumberErr(e.error?.message || "");
                setMessage("");
              }}
            />
            <div className="error">{numberErr}</div>
          </Box>
          <Box display="flex">
            <Box mb="20px" mr="10px" width="50%">
              <label className="labelField">Expiration Date</label>
              <CardExpiryElement
                id='cardExpiryId'
                className="inputField"
                onChange={(e) => {
                  setDateErr(e.error?.message || "");
                  setMessage("");
                }}
              />
              <div className="error">{dateErr}</div>
            </Box>
            <Box mb="20px" width="50%">
              <label className="labelField">Security Code</label>
              <CardCvcElement
                id='cardCvvId'
                className="inputField"
                onChange={(e) => {
                  setCvvErr(e.error?.message || "");
                  setMessage("");
                }}
              />
              <div className="error">{cvvErr}</div>
            </Box>
          </Box>

          <Typography className="subHeaderText">Billing Information</Typography>
          <Box className="inputBox">
            <Typography className="textLabel">Street Address</Typography>
            <OutlinedInput
              className="textField"
              name="street"
              onChange={handleChangeOfAddCard}
              value={cardDetails.street}
              placeholder="Street Address"
              fullWidth
            />
            {handleErrorFields(cardDetailsErrors.street, "Please enter valid address")}

          </Box>

          <Box className="gridBox">
            <Box className="inputBox">
              <Typography className="textLabel">Country</Typography>

              <Autocomplete
                id="combo-box-demo"
                className="autoCompleteCustomCss"
                disableClearable
                options={countryOptions}
                onChange={handleSelectForCountry}
                value={selectedCountry}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
              />

              {handleErrorFields(cardDetailsErrors.country, "Please enter valid country")}

            </Box>
            <Box className="inputBox">
              <Typography className="textLabel">State</Typography>
              <Autocomplete
                id="combo-box-demo"
                className="autoCompleteCustomCss"
                disableClearable
                options={stateOptions}
                onChange={handleSelectForState}
                value={selectedState}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
              />

              {handleErrorFields(cardDetailsErrors.state, "Please enter valid state")}

            </Box>
          </Box>
          <Box className="gridBox">
            <Box className="inputBox">
              <Typography className="textLabel">City</Typography>

              <Autocomplete
                id="combo-box-demo"
                className="autoCompleteCustomCss"
                disableClearable
                options={cityOptions}
                value={selectedCity}
                onChange={handleSelectForCity}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
              />


              {handleErrorFields(cardDetailsErrors.city, "Please enter valid city")}

            </Box>
            <Box className="inputBox">
              <Typography className="textLabel">Zip Code</Typography>
              <OutlinedInput
                className="textField"
                onKeyDown={handleKeyDown}
                name="zip_code"
                onChange={handleChangeOfAddCard}
                value={cardDetails.zip_code}
                placeholder="Zip Code"
                fullWidth
              />
              {handleErrorFields(cardDetailsErrors.zip_code, "Please enter valid zip code")}

            </Box>
          </Box>


          <DialogActions style={{ borderTop: "1px solid #EAEAEA" }}>

            <Box className="gridBox">
              <Button data-test-id="cancelBtn" className="cancelBtn" onClick={handleCancelClose}>Cancel</Button>
              <Button data-test-id="saveBtn" type="submit" className="saveBtn">Save</Button>
            </Box>
          </DialogActions>

          {message && <div style={{ color: "red" }} id="payment-message">{message}</div>}
        </form>
        : 
        <form className="signUpFlow" onSubmit={handleSubmit}>
          <Box className="paymentBox">

            <Box className="insidePaymentBox">
              <Typography className="addPaymentText"> Add Payment Method </Typography>
              <Box className="fieldBox">
                <Typography className="paymentLabelText">Cardholder Name</Typography>
                <OutlinedInput
                  name="card_holder_name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameErr("");
                    setMessage("");
                  }}
                  onBlur={() => isNameValid()}
                  placeholder="Cardholder Name"
                  fullWidth
                  className="paymentFields"
                />
                
                <div className="error">{nameErr}</div>

              </Box>

              <Box className="fieldBox">
                <Typography className="paymentLabelText">Card Number</Typography>
                <CardNumberElement
                  id='cardNumberIdSignUp'
                  className="paymentElementFields"
                  options={{ showIcon: true }}
                  onChange={(e) => {
                    setNumberErr(e.error?.message || "");
                    setMessage("");
                  }}
                />
                <div className="error">{numberErr}</div>

              </Box>
              <Box className="fieldBox" style={{ display: "flex", gap: "10px" }}>
                <Box style={{ width : "50%"}}>
                  <Typography className="paymentLabelText">Expiry Date</Typography>
                  <CardExpiryElement
                    id='cardExpiryId'
                    className="paymentElementFields"
                    onChange={(e) => {
                      setDateErr(e.error?.message || "");
                      setMessage("");
                    }}
                  />
                  <div className="error">{dateErr}</div>
                </Box>
                <Box  style={{ width : "50%"}}>
                  <Typography className="paymentLabelText">Security Code</Typography>
                  <CardCvcElement
                    id='cardCvvId'
                    className="paymentElementFields"
                    onChange={(e) => {
                      setCvvErr(e.error?.message || "");
                      setMessage("");
                    }}
                  />
                  <div className="error">{cvvErr}</div>

                </Box>
              </Box>
              <Box className="fieldBox">
                <Typography className="paymentLabelText">Billing Address</Typography>
              </Box>
              <Box className="fieldBox">
                <Typography className="paymentLabelText">Street Address</Typography>
                <OutlinedInput 
                  name="street"
                  placeholder="Street Address"
                  fullWidth
                  className="paymentFields"
                  onChange={handleChangeOfAddCard}
                  value={cardDetails.street}
                 />
                  {handleErrorFields(cardDetailsErrors.street, "Please enter valid address")}

              </Box>
              <Box className="fieldBox" style={{ display: "flex", gap: "10px" }}>
                <Box style={{ width : "50%"}}>
                  <Typography className="paymentLabelText">Country</Typography>

                  <Autocomplete
                    id="combo-box-demo"
                    className="autoCompleteCustomSignUpCss"
                    disableClearable
                    options={countryOptions}
                    onChange={handleSelectForCountry}
                    value={selectedCountry}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                  />

                   {handleErrorFields(cardDetailsErrors.country, "Please enter valid country")}
                </Box>
                <Box style={{ width : "50%"}}>
                  <Typography className="paymentLabelText">State</Typography>
                  <Autocomplete
                    id="combo-box-demo"
                    className="autoCompleteCustomSignUpCss"
                    disableClearable
                    options={stateOptions}
                    onChange={handleSelectForState}
                    value={selectedState}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                  />

                  {handleErrorFields(cardDetailsErrors.state, "Please enter valid state")}
                </Box>
              </Box>
              <Box className="fieldBox" style={{ display: "flex", gap: "10px" }}>
                <Box style={{ width : "50%"}}> 
                  <Typography className="paymentLabelText">City</Typography>
                  <Autocomplete
                    id="combo-box-demo"
                    className="autoCompleteCustomSignUpCss"
                    disableClearable
                    options={cityOptions}
                    onChange={handleSelectForCity}
                    value={selectedCity}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                  />


                  {handleErrorFields(cardDetailsErrors.city, "Please enter valid city")}
                </Box>
                <Box style={{ width : "50%"}}>
                  <Typography className="paymentLabelText">Zip Code </Typography>
                  <OutlinedInput
                    className="paymentFields"
                    onKeyDown={handleKeyDown}
                    name="zip_code"
                    onChange={handleChangeOfAddCard}
                    value={cardDetails.zip_code}
                    placeholder="Zip Code"
                    fullWidth
                  />
                  {handleErrorFields(cardDetailsErrors.zip_code, "Please enter valid zip code")}
                </Box>

              </Box>
              <br />
            </Box>

            <Box style={{ width: "500" }}>
              <Button className="verifyBtn" type="submit" data-test-id="addCardInfo" fullWidth>Continue</Button>
            </Box>
          </Box>
        </form>
      }
               
    </R1Form>
  );
};

const R1Form = styled(Box)({
  width: "100%",
  "& .r1form": {
    boxShadow: "none !important",
    borderRadius: "0 !important",
    "@media (max-width: 600px)": {
      minWidth: "100% !important",
      width: "100% !important",
      borderTop: "1px solid #dcdcdc",
      borderRight: "none",
      padding: "20px 0 0 !important",
    },
    "& .labelField": {
        fontWeight: 500,
        color: "#667085",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        marginBottom: "5px"
    },
    "& .inputField": {
        border: "1px solid #CBD5E1",
        borderRadius: "30px",
        padding: "10px 14px",
    },
    "& .cancelBtn": {
        height: "40px",
        width: "80px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        border: "1px solid #D0D5DD",
        borderRadius: 30,
        color: "#475467",
    },
    "& .saveBtn": {
        height: "40px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        width: "120px",
        color: "white",
        background: "#3173E1",
    },
    "& .error": {
      color: "red",
    },
    "& .autoCompleteCustomCss": {
      height: 40,
      width: 185,
    },
    "& .autoCompleteCustomCss .MuiOutlinedInput-root": {
      borderRadius: "30px !important"
    }
  },
  "& .signUpFlow": {
    "& .paymentElementFields": {
      padding: '18.5px 14px !important',
      border: '1px solid #E7E7E7 !important',
      margin: "10px 0 !important",
      borderRadius: '8px !important',
      boxShadow: "0px 4px 5px 0px #E7E7E7 !important"
    },
    "& .error": {
      color: "red",
    },
    "& .autoCompleteCustomSignUpCss": {
      height: 56,
      margin : "10px 0"
    },
    "& .autoCompleteCustomSignUpCss .MuiOutlinedInput-root": {
      borderRadius: "8px !important"
    },
    "& .autoCompleteCustomSignUpCss .MuiInputBase-input": {
      height: "35px !important"
    }
  }
  
});

export default CardComponent;