// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { BookingDates } from '../../../components/src/TypeInterfaces.web'

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isGoogleCalendarSync : boolean
  bookingInfo : BookingDates
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GoogleCalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here 
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  navigateToHome = () => {
    this.props.navigation.navigate("Home");
  }

  // Customizable Area End
}

// Customizable Area End