import React, { useState, FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slider,
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  styled,
  Checkbox,
  Switch,
  withStyles,
  createStyles,
  Theme,
  OutlinedInput,
} from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { bluetoothIcon,addHomeIcons3,addHomeIcons4, addHomeIcons1, addHomeIcons2, addHomeIcons5, addHomeIcons6,addHomeIcons8, addHomeIcons9, addHomeIcons10, addHomeIcons11, addHomeIcons7, appleIcon, alarmIcon, androiadIcon, bikeIcon, cameraIcon, keyIcon, seatIcon } from '../../blocks/catalogue/src/assets'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { starIcon, skipIcon } from './asset';
import Rating from '@material-ui/lab/Rating';
import { userAuthDisplayPopup } from './asset';
import { FiltersCriteria } from './TypeInterfaces.web';
import moment from 'moment';

interface FilterModalProps {
  open: boolean;
  handleClose: () => void;
  homeTypeData: any[]
  cataloygeType: string
  submitFilterData: (payload : FiltersCriteria ) => void
  homeCatogoryId:  number
  carCatogoryId:  number
}

export const AntSwitch = withStyles((theme: Theme) =>
createStyles({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
    overflow: "inherit"
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#3173E1',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: "12px",
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#F2F4F7",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}),
)
(Switch);

const AirbnbSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0",
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "black",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
    color: "#3173E1"
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3,
  },
})(Slider);

export const CustomSlider = withStyles({
  root: {
    color: '#3173E1',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);


const FilterModal: FC<FilterModalProps> = ({ open, handleClose, homeTypeData, cataloygeType, submitFilterData, homeCatogoryId, carCatogoryId }) => {
  const currentDate = new Date();
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);
  const [showAll, setShowAll] = useState(false)
  const [bedsCount, setBedsCount] = useState(6)
  const [bathCount, setBathCount] = useState(6)
  const [guestCount, setGuestCount] = useState(0)
  const [rating, setRating]= useState<number[]>([0]);
  const [satrtDate, setStartDate] = useState(moment(currentDate).format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment(currentDate).format('YYYY-MM-DD'))
  const [feature, setFeature] = useState<any[]>([])

  const homeFeatureChange = (event : React.ChangeEvent<HTMLInputElement>, keyName: string) => {

    if (event.target.checked) {
      setFeature((prevState) => [...prevState, keyName]);
    } else {
      setFeature((prevState) => prevState.filter((rate) => rate !== keyName));
    }
  }


  const ratingChange = (event : React.ChangeEvent<HTMLInputElement>) => {

    const ratingValue = parseInt(event.target.value)

    if (event.target.checked) {
      setRating((prevState) => [...prevState, ratingValue]);
    } else {
      setRating((prevState) => prevState.filter((rate) => rate !== ratingValue));
    }

  }


  const handlePriceChange = (event: any, newValue: number | number[]) => {
    setPriceRange(newValue as [number, number]);
  };

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  const HomeFeatures = [
    {id : 1, icon: addHomeIcons1 , label: "Wifi", key: "wifi"},
    {id : 2, icon: addHomeIcons2, label: "Air Condition", key: "air_conditioning"},
    {id : 3, icon: addHomeIcons3, label: "Washer", key: "washer"},
    {id : 4, icon: addHomeIcons4, label: "TV", key: "tv"},
    {id : 5, icon: addHomeIcons5, label: "Pool", key: "pool"},
    {id : 6, icon: addHomeIcons6, label: "BBQ Gril", key: "bbq_gril"},
    {id : 7, icon: addHomeIcons7, label: "Hot hub", key: "hot_hub"},
    {id : 8, icon: addHomeIcons8, label: "Fire Pit", key: "fire_pit"},
    {id : 9, icon: addHomeIcons9, label: "Exercide equipment", key: "exercide_equipment"},
    {id : 10, icon: addHomeIcons6, label: "Indoor Fireplant", key: "indoor_fireplant"},
    {id : 11, icon: addHomeIcons10, label: "Beach Access", key: "beach_access"},
    {id : 12, icon: addHomeIcons11, label: "Pool Table",  key: "pool"},
  ]

  const CarFeatures = [
    {id : 1, icon: bluetoothIcon, label: "Bluetooth", check: false, key: "bluetooth"},
    {id : 2, icon: appleIcon, label: "AppleCanPlay", check: false, key: "apple_car_play"},
    {id : 3, icon: keyIcon, label: "Keyless entry", check: false, key: "keyless_entry"},
    {id : 4, icon: seatIcon, label: "Heated seats", check: false, key: "heated_seats"},
    {id : 5, icon: androiadIcon, label: "Android Auto", check: false, key: "andriod"},
    {id : 6, icon: cameraIcon, label: "Backup Camera", check: false, key: "back_camera"},
    {id : 7, icon: bikeIcon, label: "Bike rack", check: false, key: "bike_rack"},
    {id : 8, icon: alarmIcon, label: "Blindspot Warning", check: false, key: "blindspot_warning"},
  ]

  const displayedHomeFeatures = showAll ? HomeFeatures : HomeFeatures.slice(0, 4);
  const displayedCarFeatures = showAll ? CarFeatures : CarFeatures.slice(0, 4);

  const bedsFilter = [
    { id: "6", label : "Any", value: 6},
    { id: "1", label : "1", value: 1},
    { id: "2", label : "2", value: 2},
    { id: "3", label : "3", value: 3},
    { id: "4", label : "4", value: 4},
    { id: "5", label : "5", value: 5},
  ]

  const bathFilter = [
    { id: "6", label : "Any", value: 6},
    { id: "1", label : "1", value: 1},
    { id: "2", label : "2", value: 2},
    { id: "3", label : "3", value: 3},
    { id: "4", label : "4", value: 4},
    { id: "5", label : "5", value: 5},

  ]

  const guestsFilter = [
    { id: "1", label : "1", value: 1},
    { id: "2", label : "2", value: 2},
    { id: "3", label : "3", value: 3},
    { id: "4", label : "4", value: 4},
    { id: "5", label : "5", value: 5},

  ]

  const resetFilters = () => {
    setBedsCount(6)
    setGuestCount(1)
    setBathCount(6)
    setPriceRange([0,0])
    setRating([])
    setStartDate(moment(currentDate).format('YYYY-MM-DD'))
    setEndDate(moment(currentDate).format('YYYY-MM-DD'))
    setFeature([])

    handleClose()
  }

  const applyFilter = () => {

    if(!userAuthDisplayPopup()) return false;

    const payload = {
      catogory_id : cataloygeType === "Home" ? homeCatogoryId : carCatogoryId,
      type: cataloygeType.toLowerCase(),
      price_max: priceRange[1],
      price_min: priceRange[0],
      start_date: moment(satrtDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      bedrooms_count: bedsCount,
      bathrooms_count: bathCount,
      guests_count: guestCount,
      rating: Math.max(...rating)
    }

    submitFilterData(payload)
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <MainWrapper>
        <DialogTitle style={{ borderBottom: "1px solid #EAEAEA", padding: "8px 24px" }}>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className='filterText'>Filter</Typography>
            <IconButton edge="end" color="inherit" onClick={resetFilters}>
              <HighlightOffOutlinedIcon style={{ color : "#475467"}} />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto', overflowX: "hidden" }}>
          <Typography className='priceRangeText'>Price Range</Typography>
          <Box style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginBottom: 12, marginTop: 8 }}>
            <button style={{ background: '#3173E1', borderRadius: 6, padding: '11px 29px', border: 'none', color: 'white', fontWeight: 600, fontSize: 16 }}>${priceRange[0]} - ${priceRange[1]}</button>
            <Box style={{ background: '#3173E1', width: 10, height: 10, rotate: '45deg', position: 'absolute', bottom: '-5px', left: '50%', transform: 'translateX(-50%)' }}></Box>
          </Box>
          <AirbnbSlider
            value={priceRange}
            onChange={handlePriceChange}
            min={0}
            max={10000}
          />

          <Divider style={{ margin: "20px 0px" }} />

          { cataloygeType === "Home" && <>
          

          {/* Price Range Filter */}
            <Box>
              <Box style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
                <Box>
                  <Typography className='minPriceText'>Min Price</Typography>
                  <StyledInput value={`$${priceRange[0]}`} readOnly />
                </Box>
                <Box>
                  <Box className='skipIcon'>
                    <img src={skipIcon} alt='skip' />
                  </Box>
                </Box>
                <Box>
                  <Typography className='maxPriceText'>Max Price</Typography>
                  <StyledInput value={`$${priceRange[1]}`} readOnly />
                </Box>
              </Box>
            </Box>
          <Divider style={{ margin: "20px 0px" }} />

          {/* Home Filter */}
          { cataloygeType === "Home" && <>
          <HomeType>
            <Typography className='headerTypeText' style={{ marginBottom: "15px" }}>House Type</Typography>
            <Grid container spacing={2}>
              {homeTypeData.map((arrayVal, index) => (
                <Grid item xs={6} key={index} style={{ display: "flex", justifyContent: "center" }}>
                  <Box className="homeBox">
                    <Box style={{ padding: 15 }}>
                      <img style={{ width: 39, height: 39 }} src={arrayVal.icon} alt="previewImg" />
                      <Typography className="homeText">{arrayVal.label}</Typography>
                    </Box>
                    <Box style={{ position: "absolute", right: "10" }}>
                      <Checkbox
                        data-test-id="selectedHome"
                        name={arrayVal.name}
                        color="primary" />
                    </Box>
                  </Box>
                </Grid>
              ))}

            </Grid>
          </HomeType>

          <Divider style={{ margin: "20px 0px" }} />
          </>
          }

          {/* Bedrooms Filter */}
          <Box>
            <Typography className='headerTypeText'>Bedrooms</Typography>
            <Grid container spacing={2}>
              {bedsFilter.map((option, index) => (
                <Grid item xs={2} key={index}>
                  <Box onClick={() =>setBedsCount(option.value)} className={option.value === bedsCount ? 'activeKey' : "normalKey"}>
                    {option.label}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          {/* Bathrooms Filter */}
          <Box>
            <Typography className='headerTypeText'>Bathrooms</Typography>
            <Grid container spacing={2}>
              {bathFilter.map((option, index) => (
                <Grid item xs={2} key={index}>
                  <Box onClick={() => setBathCount(option.value)}  className={option.value === bathCount ? 'activeKey' : "normalKey"}>
                    {option.label}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          {/* Guests Filter */}
          <Box>
            <Typography className='headerTypeText'>Guest</Typography>
            <Grid container spacing={2}>
              {guestsFilter.map((option, index) => (
                <Grid item xs={2} key={index}>
                  <Box onClick={() => setGuestCount(option.value)}  className={option.value === guestCount ? 'activeKey' : "normalKey"}>
                    {option.label}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          </>}

          {/* Amenties Filter */}
          <Box>
            <Typography className='headerTypeText'>{cataloygeType === "Home" ? "Amenities" : "Features"}</Typography>
            {cataloygeType === "Home" ?
              <Grid container>
                {displayedHomeFeatures.map((item) =>
                  <Grid key={item.id} item xs={6}>
                    <Box className="featuresBox">
                      <Box style={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <img src={item.icon} alt={item.label} />
                        <Typography className="featureText">{item.label}</Typography>
                      </Box>
                      <Box>
                        <Checkbox
                          data-test-id="homeFeatureId"
                          color="primary"
                          onChange={(event) => homeFeatureChange(event, item.key)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid> :
              <Grid container>
                {displayedCarFeatures.map((item) =>
                  <Grid key={item.id} item xs={6}>
                    <Box className="featuresBox">
                      <Box style={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <img src={item.icon} alt={item.label} />
                        <Typography className="featureText">{item.label}</Typography>
                      </Box>
                      <Box>
                        <Checkbox
                          data-test-id="homeFeatureId"
                          color="primary"
                          onChange={(event) => homeFeatureChange(event, item.key)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            }

            <Button className='btnShow' onClick={handleToggle} endIcon={showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
              {showAll ? "Show less" : "Show more"}
            </Button>

          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          {/* Rating Filter */}
          <Box>
            <Typography className='headerTypeText'>Customer Ratings</Typography>

            {[5, 4, 3, 2, 1].map((option, index) => (
              <Box className='ratingBox' key={index}>
                <Rating readOnly value={option} icon={<img src={starIcon} alt="icon" />} emptyIcon={<StarBorderIcon fontSize="inherit" />} />
                <Checkbox value={option} onChange={ratingChange} color='primary' />
              </Box>
            ))}

          </Box>

          <Divider style={{ margin: "20px 0px" }} />

          {/* Date Range Filter */}
          <Box>
            <Box style={{ display: "flex", justifyContent: "space-evenly", gap: "10px" }}>
              <Box>
                <Typography className='minPriceText'>Start Trip</Typography>
                <OutlinedInput className='dateField' value={satrtDate} onChange={(event) => setStartDate(event.target.value)} type='date' />
              </Box>
              <Box>
                <Box className='skipIcon'>
                  <img src={skipIcon} alt='skip' />
                </Box>
              </Box>
              <Box>
                <Typography className='maxPriceText'>End Trip</Typography>
                <OutlinedInput className='dateField' value={endDate} onChange={(event) => setEndDate(event.target.value)} type='date' />
              </Box>
            </Box>
          </Box>
          <Divider style={{ margin: "20px 0px" }} />

          {/* Booking Options Filter */}
          {cataloygeType === "Home" &&
          <Box>
            <Typography className='headerTypeText'>Booking Options</Typography>
            <Box style={{ display: "flex", justifyContent: "space-between", margin: "15px 0", alignItems: "center"}}>
              <Box>
                <Typography className='bookingOptText'>Instant Book</Typography>
                <Typography className='bookingOptSubText'>Listings you can book without waiting for Host approval</Typography>
              </Box>
              <AntSwitch />
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between", margin: "15px 0", alignItems: "center"}}>
              <Box>
                <Typography className='bookingOptText'>Self Check-in</Typography>
                <Typography className='bookingOptSubText'>Easy access to the property once you arrive</Typography>
              </Box>
              <AntSwitch />
            </Box>
            <Box style={{ display: "flex", justifyContent: "space-between", margin: "15px 0", alignItems: "center"}}>
              <Box>
                <Typography className='bookingOptText'>Free cancellation</Typography>
                <Typography className='bookingOptSubText'>Only show stays that offer free cancellation</Typography>
              </Box>
              <AntSwitch />
            </Box>
            
            
          </Box>}

          

        </DialogContent>
        <Box>
          <Divider />
          <Box style={{ display: "flex", gap: "15px", margin: "20px 25px" }}>
            <Button className='resetBtn' fullWidth onClick={resetFilters}>Reset</Button>
            <Button className='saveBtn' fullWidth onClick={applyFilter}>Show Results</Button>
          </Box>
        </Box>
        
      </MainWrapper>
    </Dialog>
  );
};

const MainWrapper = styled(Box)({
   "& .filterText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'left',
    color: '#22395D'
   },
   "& .priceRangeText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#22395D',
    textAlign: 'left',
   },
   "& .headerTypeText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: 600,
    color: '#22395D'
   },
   "& .minPriceText": {
     fontSize: '14px',
    fontFamily: 'Plus Jakarta Sans',
    textAlign: 'left',
    fontWeight: 500,
    color: '#22395D'
   },
   "& .maxPriceText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 500,
    color: '#22395D'
   },

   "& .activeKey" : {
    backgroundColor: '#3173E1',
    textAlign: 'center',
    color: 'white',
    borderRadius: '20px',
    marginTop: "10px",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    padding: "10px 15px",
    fontWeight: 500,
    cursor: "pointer"
   },

   "& .normalKey" : {
    border: "1px solid #DCDEE4",
    textAlign: 'center',
    padding: '10px 15px',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '20px',
    cursor: "pointer",
    marginTop: "10px"
   },
  "& .featureText": {
    padding: "0 10px",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'left',
    maxWidth:"70px"
   },
   "& .featuresBox" : {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: '8px',
    border: '1px solid transparent',
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    padding: "5px",
    margin: "10px 20px 10px 0"
   },
   "& .btnShow" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    textTransform: "none",
    color: "#3173E1",
    marginTop: 10
   },
   "& .ratingBox" : {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
   },

   "& .resetBtn" : {
     height: 45,
     border: "1px solid #23395D",
     borderRadius: "14px",
     fontWeight: 600,
     color: "#22395D",
     textTransform: "none"
   },
   "& .saveBtn" : {
    height: 45,
    borderRadius: "14px",
    color: "white",
    background: "#3173E1",
    fontWeight: 600,
    textTransform: "none"
   },
   "& .bookingOptText" : {
     fontSize: '14px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 500,
    color:"#0F172A",
   },
   "& .bookingOptSubText" : {
    fontSize: '12px',
   fontFamily: 'Plus Jakarta Sans',
   fontWeight: 400,
   color:"#475467"
  }, 
  "& .skipIcon" : {
    textAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
    marginTop: "10px"
  }, 
  "& .dateField" : {
    width: '100%',
    padding: '10px',
    borderRadius: '12px',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 600,
    color: "#4C6082",
    height:"50px",    
  }


})

const StyledInput = styled('input')({
  width: '100%',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '12px',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  textAlign: 'left',
  fontWeight: 600,
  color: "#4C6082"
});

const HomeType = styled(Box)({
  "& .homeBox" : {
    height: 100,
    width: 180,
    border: "1px solid #0F73EE",
    borderRadius: 8,
    display: "flex",
    flexDirection : "column",
    position: "relative"
  },
  "& .homeText" : {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Plus Jakarta Sans',
    color:"#23395D",
    margin:"2px 0"
  }
})

export default FilterModal;
