// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { apiCalling, userAuthDisplayPopup, convertUrlsToFiles } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  addPhotos : boolean,
  catalougesPhotos : File[]
  uploadedPhotosUrl: string[],
  accountId : string,
  loading :boolean
  bookedSlotId : string | number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostReservationCheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileRef: React.RefObject<HTMLInputElement>
  savePhotosApiCallId : string = ""
  getProfileApiCallId: string = ""
  getImagesApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      addPhotos: true,
      catalougesPhotos: [],
      uploadedPhotosUrl: [],
      accountId : "",
      loading: false,
      bookedSlotId : ""
    };
    this.fileRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId)  {
        case this.getProfileApiCallId : 
            return this.handleResponseForProfileApi(responseJson)
        case this.savePhotosApiCallId :
            return this.handleResponseForSaveImagesApi(responseJson)
        case this.getImagesApiCallId : 
            return this.handleImagesResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const bookedSlotId = this.props.navigation.getParam("reservationId")
    this.setState({ bookedSlotId: bookedSlotId })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.bookedSlotId !== this.state.bookedSlotId) {
        this.getUserProfile()
        this.getImages()
    }
  }

  handleImagesResponse = async (responseJson: any) => {
    if(responseJson && responseJson.data) {
       const result = responseJson.data.attributes
       const modifiedImgArr = result.images.map((item: any) => item.url)

       const imgArr = await convertUrlsToFiles(result.images).then((files) => {
          return files
       });

       this.setState({ uploadedPhotosUrl : modifiedImgArr, loading: false, catalougesPhotos : imgArr})
    }
  }

  handleResponseForProfileApi = (responseJson: any) => {
    if (responseJson && responseJson.user && responseJson.user.data) {
      this.setState({ accountId: responseJson.user.data.id })
    } else {
      userAuthDisplayPopup()
    }
  }

  handleResponseForSaveImagesApi = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.id !== null) {
      toast.success("All Photos Saved Successfully")
      this.getImages()
    } else {
      this.setState({ loading : false})
      toast.error("Something went wrong")
    }
  }

  handleNavigationToBackPage = () => {
    this.props.navigation.goBack()
  }

  handleToggleAddPhostos = () => {
    this.setState({ addPhotos : false})
  }

  handleCataloguesPhotosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        catalougesPhotos: [...prevState.catalougesPhotos, ...newFiles],
        uploadedPhotosUrl: [...prevState.uploadedPhotosUrl, ...fileURLs]
      }));
    }

  };

  handleDeletePhotos = (index: number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.catalougesPhotos];
      const updatedPreviews = [...prevState.uploadedPhotosUrl];

      updatedFiles.splice(index, 1);
      updatedPreviews.splice(index, 1);

      return {
        catalougesPhotos: updatedFiles,
        uploadedPhotosUrl: updatedPreviews,
      };
    });
  };

  handleRefCall = () => {
    if (this.fileRef.current) {
      this.fileRef.current.click();
    }
  }

  getUserProfile = () => {
    const header = {
      'token': localStorage.getItem("userToken")
  }

  this.getProfileApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getUserProfileApiEndPoint,
  })
  }

  saveImages = () => {
    
    if (this.state.catalougesPhotos.length < 10) {
      toast.error('Please upload minimum 10 images to continue.');
      return;
    }

    this.setState({ loading : true})

    const header = {
        "token": localStorage.getItem("userToken"),
      };

    const bookedSlotId = this.props.navigation.getParam("reservationId")

    const formData = new FormData()

    formData.append("trip_type", "pre_trip")
    formData.append("mileage_odometer", "0")
    formData.append("fuel_level", "0")
    formData.append("is_key_provided", `false`)

    this.state.catalougesPhotos.forEach(value => {
      formData.append("images[]", value);
    });

    this.savePhotosApiCallId = apiCalling({
        header : JSON.stringify(header),
        endPoint: configJSON.preTripCheckOutEndPoint(bookedSlotId),
        method: configJSON.apiMethodTypePost,
        body: formData
    })

  }

  getImages = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getImagesApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getTripImagesApiEndPoint(this.state.bookedSlotId,"pre_trip"),
    })
  }

  handleFileDragSelection = (selectedFilesArr: File[]) => {
    if (selectedFilesArr.length > 0) {

      const newFiles = Array.from(selectedFilesArr);
      const fileURLs = Array.from(selectedFilesArr).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        catalougesPhotos: [...prevState.catalougesPhotos, ...newFiles],
        uploadedPhotosUrl: [...prevState.uploadedPhotosUrl, ...fileURLs]
      }));
    }
  };

  // Customizable Area End
}

// Customizable Area End