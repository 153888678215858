import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { Country, State, City }  from 'country-state-city';
import swal from "sweetalert";


interface ApiParams {
    header: string 
    endPoint: string
    method: string 
    body?: string | FormData| unknown;
}

export const checkOutImageIcon = require("./assets/checkOutImage.png")
export const carCheckOutImageIcon = require("./assets/carCheckout.png")
export const shapeIcon1 = require("./assets/shape1.png")
export const shapeIcon2 = require("./assets/shape2.png")
export const notificationIcon1 = require("./assets/notification1.png")
export const notificationIcon2 = require("./assets/notification2.png")
export const notificationIcon3 = require("./assets/notification3.png")
export const notificationIcon4 = require("./assets/notification4.png")
export const starIcon = require("./assets/starIcon.svg")
export const skipIcon = require("./assets/skipIcon.svg")
export const defaultProfileIcon = require("./assets/defaultProfileIcon.png")
export const carScheduleIcon = require("./assets/carIcon.svg")
export const homeScheduleIcon = require("./assets/homeIcon.svg")
export const apiKey = "AIzaSyCwwkcI-XMWref6Pp2BSEhLCsgGJgY0Jsc";
export const stripeKey = "pk_test_51NKujrFZz72x5l4q4P0wV6qQvb0WBA1FP27lJPqfKzETFVeTRii99hqZCC8BN1EaWcmXw6i0fbFT1XOzhDOmNHd0008iQtlaxj"
export const CLIENT_ID ="595436873173-90qm1t3e62u48n1ah7erq1qrm24qc6ol.apps.googleusercontent.com";
export const API_KEY = "AIzaSyD1ahIR06NW_yxbCBmgSKVFUTsqogxgk40";
export const DISCOVERY_DOC_DATA ="https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
export const SCOPES_DATA = "https://www.googleapis.com/auth/calendar";


export const apiCalling = ({ header, endPoint, method, body } : ApiParams) => {
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
}

export const truthyFeatureValue = (data : any[], checkKey : string) => {
    if(data !== null && data.length > 0 ) {
        const keyValue = data.find(item => item.title ===  checkKey)
        return keyValue ? keyValue.value : 0
       } 
    return 0
}

export const truthyCardTitleValue = (data : any[], checkKey : string) => {
    if(data !== null && data.length > 0 ) {
        const keyValue = data.find(item => item.title ===  checkKey)
        return keyValue ? keyValue.value : ""
       } 
    return ""
}

export function generateRandomNumberPlate() {
    const stateCodes : { [key: string]: string } = {
        "Andhra Pradesh": "ap",
        "Arunachal Pradesh": "ar",
        "Assam": "as",
        "Bihar": "br",
        "Chhattisgarh": "cg",
        "Goa": "ga",
        "Gujarat": "gj",
        "Haryana": "hr",
        "Himachal Pradesh": "hp",
        "Jammu and Kashmir": "jk",
        "Jharkhand": "jh",
        "Karnataka": "ka",
        "Kerala": "kl",
        "Madhya Pradesh": "mp",
        "Maharashtra": "mh",
        "Manipur": "mn",
        "Meghalaya": "ml",
        "Mizoram": "mz",
        "Nagaland": "nl",
        "Odisha": "or",
        "Punjab": "pb",
        "Rajasthan": "rj",
        "Sikkim": "sk",
        "Tamil Nadu": "tn",
        "Telangana": "ts",
        "Tripura": "tr",
        "Uttar Pradesh": "up",
        "Uttarakhand": "uk",
        "West Bengal": "wb",
        "Andaman and Nicobar Islands": "an",
        "Chandigarh": "ch",
        "Dadra and Nagar Haveli and Daman and Diu": "dd",
        "Lakshadweep": "ld",
        "Delhi": "dl",
        "Puducherry": "py"
    };

    const states = Object.keys(stateCodes);
    const randomState  = states[Math.floor(Math.random() * states.length)];

    const stateCode = stateCodes[randomState];

    const districtCode = Math.floor(Math.random() * 99) + 1;

    const charCode = String.fromCharCode(97 + Math.floor(Math.random() * 26)) + String.fromCharCode(97 + Math.floor(Math.random() * 26));

    const number = Math.floor(Math.random() * 9000) + 1000;

    const numberPlate = stateCode + districtCode.toString().padStart(2, '0') + charCode + number;

    return numberPlate
}

export const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}

export const truthyNumberValue = (checkKey: any) => {
    return Number.isNaN(checkKey) ? 0 : Number(checkKey).toFixed(1)
}

export const getDefaultCountryCodes = () => {
    const Countries = Country.getCountryByCode("IN")  
    return Countries
}

export const getAllCountries = () => {
    const AllCountries = Country.getAllCountries()
    return AllCountries
}

export const getStatesOfCountry = (countryCode : string) => {
    const AllStates = State.getStatesOfCountry(countryCode)
    return AllStates
}

export const getAllCitiesOfStateAndCountries = (countryCode : string, stateCode: string) => {
    const AllCities = City.getCitiesOfState(countryCode,stateCode)
    return AllCities
}

export const getCountryFromCountryCode = (countryCode : string) => {
    const countryName = Country.getCountryByCode(countryCode)
    return countryName
}

export const getSelectedState = (countryCode : string, stateValue : string) => {
    const AllStates = State.getStatesOfCountry(countryCode)
    const savedState = AllStates.find(val => val.name === stateValue)
    return savedState
}

export const getSelectedCity = (countryCode : string, cityValue : string) => {
    const AllCities = City.getCitiesOfCountry(countryCode)
    const savedCity = AllCities && AllCities.find(val => val.name === cityValue)
    return savedCity
}

export const getAllCitiesOFCountry = (countryCode : string) => {
    const AllCities = City.getCitiesOfCountry(countryCode)
    const modifiedArray = AllCities ? AllCities : []
    return modifiedArray
}


export const userAuthDisplayPopup = () => {
    if(!localStorage.getItem("userToken")) {
        swal("Please Login/Signup to continue","", "error")
        return false
      }
    return true
}

export const getUserRole = () => {

    return localStorage.getItem("userRole")
}

export const checkIfLogged = () => {
    if(!localStorage.getItem("userToken")) {
        return false;
    }
    return true;
};

export const refFunction = (msgRef: React.RefObject<HTMLInputElement>) => {
    if (msgRef.current) {
        msgRef.current.scrollIntoView({ behavior: 'smooth' });
    } 
}

const urlToFile = async (url: string, filename: string, mimeType: string): Promise<any> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
}

export const convertUrlsToFiles = async (images: any): Promise<File[]> => {
    const promises = images.map(async (image: any) => {
        const urlParts = image.url.split('/');
        const filename = urlParts[urlParts.length - 1];
        const mimeType = 'image/png';
        return urlToFile(image.url, filename, mimeType);
    });

    return Promise.all(promises);
}

export const getCountryName = (location : string) => {
    let stringVal = location.split(',');

    if (stringVal && stringVal.length > 0) {
        const lastElement = stringVal.pop() || ''; 
        return lastElement.trim();
    } else {
        return location.toString();
    }
}

export const getRandomNumbers = () => {
     const uniqueIds = Math.floor(100000 + Math.random() * 900000)
     return uniqueIds
}

export const redirectToURL = (url : string) => {
    window.open(url,"_blank")
}

export const mockTransactionData = {
    "data" : [
        {
            "month": "August 2024",
            "transactions": [
                {
                    "title": "Automatic Payout",
                    "created_at": "2024-08-23T08:10:05.934Z",
                    "amount": 1500.0,
                    "status" : true,
                    "booked_slots": [
                        {
                            "catalogue_name": "Latest Web Home",
                            "catalogue_type" :  "Home",
                            "amount": 1500.0,
                            "total_days": 7,
                            "start_time": "2024-10-07T00:00:00.000Z",
                            "end_time": "2024-10-14T00:00:00.000Z",
                            "image": "https://luxgonewfinal-138857-ruby.b138857.dev.eastus.az.svc.builder.cafe//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcWdKIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dead26484171432b58c7001bea43f65947a28a5b/bgImage.png"
                        }
                    ]
                },
                {
                    "title": "Manual Payout",
                    "created_at": "2024-08-23T08:12:40.269Z",
                    "amount": 5000.0,
                    "status" : false,
                    "booked_slots": [
                        {
                            "catalogue_name": "Mercideeze Benz",
                            "catalogue_type" :  "Car",
                            "amount": 5000.0,
                            "total_days": 5,
                            "start_time": "2024-09-11T00:00:00.000Z",
                            "end_time": "2024-09-16T00:00:00.000Z",
                            "image": "https://luxgonewfinal-138857-ruby.b138857.dev.eastus.az.svc.builder.cafe//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcWdKIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dead26484171432b58c7001bea43f65947a28a5b/bgImage.png"
                        }
                    ]
                },
                {
                    "title": "Automatic Payout",
                    "created_at": "2024-08-23T08:31:16.186Z",
                    "amount": 500.0,
                    "status" : false,
                    "booked_slots": [
                        {
                            "catalogue_name": "Lamborghini Urus",
                            "catalogue_type" :  "Car",
                            "amount": 500.0,
                            "total_days": 3,
                            "start_time": "2024-10-22T00:00:00.000Z",
                            "end_time": "2024-10-25T00:00:00.000Z",
                            "image": "https://luxgonewfinal-138857-ruby.b138857.dev.eastus.az.svc.builder.cafe//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcWdKIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dead26484171432b58c7001bea43f65947a28a5b/bgImage.png"
                        }
                    ]
                }
            ]
        },
        {
            "month": "November 2024",
            "transactions": [
                {
                    "title": "Manual Payout",
                    "created_at": "2024-11-06T10:21:40.472Z",
                    "status" : true,
                    "amount": 80.0,
                    "booked_slots": [
                        {
                            "catalogue_name": "Modern WeHo Home",
                            "catalogue_type" :  "Home",
                            "amount": 80.0,
                            "total_days": 8,
                            "start_time": "2024-11-07T00:00:00.000Z",
                            "end_time": "2024-11-14T00:00:00.000Z",
                            "image": "https://luxgonewfinal-138857-ruby.b138857.dev.eastus.az.svc.builder.cafe//rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcWdKIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dead26484171432b58c7001bea43f65947a28a5b/bgImage.png"
                        }
                    ]
                }
            ]
        }
    ]
}