// Customizable Area Start
import React from "react";
import {
   Box,
   styled,
   Typography,
   Button,
   Grid,
   Divider,
   OutlinedInput
} from "@material-ui/core"
import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { calenderIcon, searchIcon } from "../../../blocks/reservations/src/assets"; 
import CustomPagination from "../../../components/src/CustomPagination.web";




import BookingHistoryController, {
    Props,
  } from "./BookingHistoryController";
import { filterReservationIcon } from "./assets";

class BookingHistory extends BookingHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />

        {this.state.loader ? <Loader loading={true} /> :
          <HistoryWrapper>
              <Box>
                <Button
                  onClick={this.navigateToBackPage}
                  data-test-id="backBtn"
                  className="backButton"
                  startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                  Back
                </Button>
              </Box>
              <Typography className="historyText"> Booking History</Typography>

              <Box style={{ display: "flex", gap: "10px", alignItems: "center", margin: "30px 0" }}>
                <OutlinedInput
                  placeholder="Search here"
                  className="searchBox"
                  startAdornment={<img src={searchIcon} />}
                  onChange={this.searchHistoryData}
                  data-test-id="searchTerm"
                />
                <Button startIcon={<img src={filterReservationIcon} />} className="filterBtn">Filter</Button>
              </Box>


                <Box>
                  {this.state.bookingHistoryData.length > 0 &&
                    <Grid container spacing={3}>
                      {this.state.bookingHistoryData.map(cardValue =>
                        <Grid item md={6} key={cardValue.catalougeId}>
                          <Box className="historyCard">
                            <Grid container spacing={3}>
                              <Grid item md={4}>
                                <img
                                  className="imgCss"
                                  src={cardValue.imgUrl}
                                />
                              </Grid>
                              <Grid item md={8}>
                                <Typography className="headerText">{cardValue.catalougeName}</Typography>
                                <Typography className="subHeaderText">Renter: <span className="renterName">{cardValue.renterName}</span></Typography>
                                <Box style={{ display: "flex", alignItems: "center", gap: "8px", margin: "5px 0" }}>
                                  <img src={calenderIcon} alt="icon" />
                                  <Typography className="durationText"> {cardValue.tripDuration}</Typography>
                                </Box>

                                <Typography className="subHeaderText">Final Trip Earnings: <span className="renterName">${cardValue.tripCost}</span></Typography>
                                <Divider style={{ margin: "15px 0" }} />
                                <Box style={{ margin: "20px 0", display: "flex", gap: 8 }}>
                                  <Button className="modifyBtn" data-test-id="reportBtn" onClick={() => this.navigateToReportPage(cardValue.catalougeId)}>Report an issue</Button>
                                  <Button className="viewTripBtn" data-test-id="viewTripBtn" onClick={() => this.navToTripInfo(cardValue.catalougeId)}>View trip details</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                      )}

                    </Grid>
                  }

                  <Box style={{ margin : "20px 0"}}>
                    <CustomPagination 
                         totalPages={this.state.totalPages}
                         currentPage={this.state.currentPage}
                         perPage={this.state.perPage}
                         onPaginationChange={this.handleOnPaginationChange}
                         onPerPageChange={this.handleOnPerPageChange}
                         onPreviousClick={this.onPreviousPageChange}
                         onNextClick={this.onNextPageChange}
                         data-test-id="customPagination"
                         perPageOptions={[2,5,9]}
                     />
                  </Box>
                </Box>

          </HistoryWrapper>
        }
        <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default BookingHistory;

const HistoryWrapper = styled(Box)({
  maxWidth: "1220px",
  margin: "40px auto",
  "& .backButton": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textTransform: "none",
    textAlign: 'left',
    color: '#667085',
    background: "white"
  },
  "& .historyText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
    fontWeight: 600,
    color: '#23395D',
    margin: "15px 0"
  },
  "& .historyCard": {
    border: "1px solid #D0D5DD",
    padding: "16px",
    borderRadius: "12px",
  },
  "& .headerText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: 600,
    color: '#23395D',
    marginBottom: 8
  },
  "& .subHeaderText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#667085',
  },
  "& .renterName": {
    fontWeight: 700,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    color: '#23395D'
  },
  "& .durationText": {
    fontWeight: 500,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    color: '#3173E1'
  },
  "& .modifyBtn": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: "none",
    width: 180,
    height: 40,
    color: '#3173E1',
    background: "white",
    border: "1px solid #3173E1",
    borderRadius: "12px"
  },
  "& .viewTripBtn": {
    width: 180,
    height: 40,
    color: 'white',
    background: "#3173E1",
    borderRadius: "12px",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
  },
  "& .imgCSS": {
    height: 130,
    width: 180,
    borderRadius: "12px"
  },
  "& .filterBtn" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
    width: 90,
    height: 40,
    color: 'white',
    background: "#3173E1",
    borderRadius: "12px",
  },
  "& .searchBox" : {
    padding: "10px 16px",
    fontFamily: 'Plus Jakarta Sans',
    height: 40,
    width: "500px",
    fontSize: "14px",
    color: "#475467",
    borderRadius: "12px",
    fontWeight: 600
  },
  "& .searchBox .MuiOutlinedInput-input" : {
    padding : 10,
  },
})

// Customizable Area End